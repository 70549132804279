import { Row, Col, Input, Select } from "antd"

const Preferences = ({ preferences,setPreferences }) => {
    const labelStyle = {
        fontSize: "16px",
        marginBottom: "5px"
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setPreferences((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const burialOptions = [
        {
            label: "Normal Burial",
            value: "Normal Burial"
        },
        {
            label: "Cremation",
            value: "Cremation"
        },
    ]

    const options = [
        {
            label: "Yes",
            value: "Yes"
        },
        {
            label: "No",
            value: "No"
        },
    ]

    const vehicleTypes = [
        {
            label: "Bus",
            value: "Bus"
        },
        {
            label: "Van",
            value: "Van"
        },
        {
            label: "Limousine",
            value: "Limousine"
        },
    ]

    const vehicles = [
        {
            label: "1",
            value: "1"
        },
        {
            label: "2",
            value: "2"
        },
        {
            label: "3",
            value: "3"
        },
    ]

    return (
        <Row gutter={[26, 16]}>
            <Col span={12}>
                <div style={labelStyle}>Would you like to be buried or cremated</div>
                <Select 
                    size="large"
                    options={burialOptions} 
                    defaultValue="Select" 
                    onChange={(value)=>handleChange({
                        target:{
                            value: value,
                            name: "burialType"
                        }
                    })}
                    style={{width:"100%"}}
                />
            </Col>
            <Col span={12}>
                <div style={labelStyle}>Do you require transport for family and friends</div>
                <Select 
                    size="large"
                    options={options} 
                    defaultValue="Select" 
                    onChange={(value)=>handleChange({
                        target:{
                            value: value,
                            name: "isTransportRequired"
                        }
                    })}
                    style={{width:"100%"}}
                />
            </Col>
            {
                preferences.isTransportRequired === "Yes" && (
                    <>
                        <Col span={12}>
                            <div style={labelStyle}>Select the type of vehicle you require</div>
                            <Select 
                                size="large"
                                options={vehicleTypes} 
                                defaultValue="Select" 
                                onChange={(value)=>handleChange({
                                    target:{
                                        value: value,
                                        name: "vehicleTypeForMourners"
                                    }
                                })}
                                style={{width:"100%"}}
                            />
                        </Col> 
                        <Col span={12}>
                            <div style={labelStyle}>How many vehicle do you need</div>
                            <Select 
                                size="large"
                                options={vehicles} 
                                defaultValue="Select" 
                                onChange={(value)=>handleChange({
                                    target:{
                                        value: value,
                                        name: "numberOfVehicles"
                                    }
                                })}
                                style={{width:"100%"}}
                            />
                        </Col>                   
                    </>
                )
            }
        </Row>
    )
}

export default Preferences