import { Avatar, Col, Drawer, Flex, Row, Space } from "antd"
import { Link } from "react-router-dom"
import { BsPatchQuestion, BsSim, BsWallet2 } from "react-icons/bs"
import { MdOutlineExitToApp, MdOutlinePersonOutline, MdOutlinePinDrop } from "react-icons/md"

const ProfileManagementDrawer= ({drawerOpen,closeDrawer})=>{

    const profileLinks = [
        {
            title: "Manage Account",
            link: "/manage-account",
            icon: <MdOutlinePersonOutline fontSize="25px" />,
        },
        {
            title: "Bookings & Trips",
            link: "/manage-profile",
            icon: <MdOutlinePinDrop fontSize="25px" />,
        },
        {
            title: "Rewards & Wallet",
            link: "/manage-profile",
            icon: <BsWallet2 fontSize="25px" />,
        },
        {
            title: "Help",
            link: "/manage-profile",
            icon: <BsPatchQuestion fontSize="25px" />,
        },
        {
            title: "Sign Out",
            link: "/manage-profile",
            icon: <MdOutlineExitToApp fontSize="25px" />,
        }
    ]
    const profileLinks2 = [
        {
            title: "Manage Account",
            link: "/manage-account",
            icon: <MdOutlinePersonOutline  fontSize="30px" />,
        },
        {
            title: "Connect",
            link: "/manage-account",
            icon: <BsSim  fontSize="30px" />,
        },
        {
            title: "Bookings & Trips",
            link: "/manage-profile",
            icon: <MdOutlinePinDrop  fontSize="30px" />,
        },
        {
            title: "Rewards & Wallet",
            link: "/manage-profile",
            icon: <BsWallet2  fontSize="30px" />,
        },
        {
            title: "Help",
            link: "/manage-profile",
            icon: <BsPatchQuestion  fontSize="30px" />,
        },
        {
            title: "Sign Out",
            link: "/manage-profile",
            icon: <MdOutlineExitToApp  fontSize="30px" />,
        },
        {
            title: "Manage Account",
            link: "/manage-account",
            icon: <MdOutlinePersonOutline  fontSize="30px" />,
        },
        {
            title: "Connect",
            link: "/manage-account",
            icon: <BsSim  fontSize="30px" />,
        },
        {
            title: "Bookings & Trips",
            link: "/manage-profile",
            icon: <MdOutlinePinDrop  fontSize="30px" />,
        },
        {
            title: "Rewards & Wallet",
            link: "/manage-profile",
            icon: <BsWallet2  fontSize="30px" />,
        },
        {
            title: "Help",
            link: "/manage-profile",
            icon: <BsPatchQuestion  fontSize="30px" />,
        },
        {
            title: "Sign Out",
            link: "/manage-profile",
            icon: <MdOutlineExitToApp  fontSize="30px" />,
        },
        {
            title: "Bookings & Trips",
            link: "/manage-profile",
            icon: <MdOutlinePinDrop  fontSize="30px" />,
        },
        {
            title: "Rewards & Wallet",
            link: "/manage-profile",
            icon: <BsWallet2  fontSize="30px" />,
        },
        {
            title: "Help",
            link: "/manage-profile",
            icon: <BsPatchQuestion  fontSize="30px" />,
        },
        {
            title: "Sign Out",
            link: "/manage-profile",
            icon: <MdOutlineExitToApp  fontSize="30px" />,
        },
        
    ]
    return (
        <Drawer
            title="Profile Management"
            placement={"left"}
            onClose={closeDrawer}
            open={drawerOpen}
            width={700}
        >
            <Row gutter={10}>
                <Col span={8} >
                    <Flex  align="center" justify="center" vertical>
                        <Avatar style={{color:"white",backgroundColor:"#31317f"}} size={55}>P</Avatar>
                        <div style={{fontSize:"18px",marginTop:"12px",marginBottom:"16px"}}>Peter Mutsiwa</div>
                    </Flex>
                    {
                        profileLinks?.map((item, i) =>
                            <div key={i} style={{marginBottom:"18px"}}>
                                <Space>
                                    <span>{item?.icon}</span>
                                    <span>{item?.title}</span>
                                </Space>                                
                            </div>
                        )
                    }
                </Col>
                <Col span={16}>
                    <Row gutter={[16,30]}>
                        {
                            profileLinks2?.map((item, i) =>
                                <Col key={i} span={6}>
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                                        <span>{item?.icon}</span>
                                        <span style={{fontSize:"12px",marginTop:"8px"}}>{item?.title}</span>
                                    </div>   
                                </Col>
                            )
                        }
                    </Row>
                </Col>
            </Row>
        </Drawer>
    )


}

export default ProfileManagementDrawer