import {useState, useEffect} from 'react'
import { Modal,Row,Col } from 'antd'
import axios from 'axios'
import { AiOutlineSearch } from 'react-icons/ai'
import { MdArrowBack } from "react-icons/md";
import '../LifeQuotation/styles.css'
import { carMakes } from './data/mockData';

function VehicleQuotationModal({openPopup,closePopup,showQuoteSummary}) {
    const [car, setCar] = useState({})
    const [makes, setMakes] = useState(carMakes)
    const [models, setModels] = useState([])
    const [input, setInput] = useState('')
    const [activeStep, setActiveStep] = useState(1)

    function range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }
    
    const years = range(2003, 2022)
    years.reverse()

    let titles = ["What is your car's brand?", "Which car model do you own?", "Select Fuel Type", "In which year was the car registered?","Vehicle Insurance Quotation Summary"]
    let placeholders = ["Search car by brand e.g Hyundai", "Search car model e.g Grand i10", "Fuel Type", "Search registration year e.g 2016"]

    function proceedToNext(name,value) {
        setCar(prevData => ({
            ...prevData,
            [name]: value
        }))
        setActiveStep(step => step + 1)
    }

    useEffect(() => {
        car.make && (
            axios.get('https://car-data.p.rapidapi.com/cars', {
                params: {limit: '20', page: '0', make: car.make},
                headers: {
                    'X-RapidAPI-Key': '9b82ec8916msh8cd0ef40d6b86d5p15f3f4jsn91ae641f849c',
                    'X-RapidAPI-Host': 'car-data.p.rapidapi.com'
                }
            }).then(res => {
                setModels(res.data)
            }).catch(error => console.log(error))
        )
    },[car])


    useEffect(() => {
        let timer
        if (activeStep === 5) {
            timer = setTimeout(() => {showQuoteSummary(car)}, 2000)
        }

        return () => clearTimeout(timer)
    },[activeStep])

    /*
     * Defining the search component 
     */
    function Search() {
        return (
            <div className='carsearch'>
                <span>
                    <AiOutlineSearch style={{marginLeft:"10px",marginRight:"5px"}}/>
                </span>
                <input type="text" placeholder={placeholders[activeStep - 1]} value={input} onChange={e => setInput(e.target.value)} />
            </div>
        )
    }

    return (
        <Modal 
            title={
                <div style={{display: "flex",alignItems:"center"}}>
                <MdArrowBack 
                    style={{marginRight:"5px",cursor:"pointer",fontSize:"25px"}} 
                    onClick={() => setActiveStep(step => step - 1)}
                />                
                {titles[activeStep - 1]}
                </div>
            }
            open={openPopup} 
            onCancel={closePopup} 
            width={800}
            footer={false}
            centered
        >
            {activeStep === 1 && (
                    <Row className='carmakes'>
                        <Col span={24}>
                           <Search /> 
                        </Col>
                        
                        {makes.map(make => {
                            return (
                                <div className='carmake' key={make.id} onClick={() => proceedToNext('make', make.name)}>
                                    <div className='carlogo'>
                                        <img src={make.logo} alt='' />
                                    </div>
                                    <span>{make.name}</span>
                                </div>
                            )
                        })}
                    </Row>                

            )}
            {activeStep === 2 && (
                <Row className='carmodels animated fadeIn'>
                    <Col span={24}>
                        <Search /> 
                    </Col>
                    {models.slice(0,10).map(model => {
                        return (
                            <div className='carmodel' key={model.id} onClick={() => proceedToNext('model', model.model)}>
                                <span>{model.model}</span>
                            </div>
                        )
                    })}
                </Row>
            )}
            {activeStep === 3 && (
                <Row className='carfueltypes'>
                    <div className='fueltype' onClick={() => proceedToNext('fueltype', 'Petrol')}>
                        <span>Petrol</span>
                    </div>
                    <div className='fueltype' onClick={() => proceedToNext('fueltype', 'Diesel')}>
                        <span>Diesel</span>
                    </div>
                    <div className='fueltype' onClick={() => proceedToNext('fueltype', 'CNG')}>
                        <span>CNG</span>
                    </div>
                    <div className='fueltype' onClick={() => proceedToNext('fueltype', 'LPG')}>
                        <span>LPG</span>
                    </div>
                </Row>
            )}
            {activeStep === 4 && (
                <Row className='registrationyears'>
                    <Col span={24}>
                        <Search /> 
                    </Col>
                    {years.map(year => (
                        <div className='registrationyear' key={year} onClick={() => proceedToNext('registrationyear', year)}>
                            <span>{year}</span>
                        </div>
                    ))}
                </Row>
            )}
            {activeStep === 5 && (
                <div className="divLoader">
                    <span className="loader" />
                    <span className='text'> Generating Quotation</span>
                </div>
            )}
        </Modal>        
    )
}

export default VehicleQuotationModal