import { useState } from "react"
import { Col, Row,Card,Space,Collapse,Checkbox,Rate,Input } from "antd"
import { MdOutlineDashboardCustomize } from "react-icons/md"
import { OrderedListOutlined } from "@ant-design/icons"
import ServicesListView from "./ServicesListView"
import ServicesGridView from "./ServicesGridView"

const displayFilters = (filters)=>(
    filters.map((filter,index)=>(
        <div style={{marginBottom:"6px"}} key={index}>
            <Checkbox>{filter}</Checkbox>
        </div>
    ))
)

const locations = ["Gauteng","Limpopo","North West","Eastern Cape","Western Cape","Free State"]
const rentalDuration = ["Less than a week", "2 Weeks", "3 Weeks", "4 Weeks"]
const brands = ["Toyota", "Madza", "Nissan", "BMW", "Mercedes Benz", "Audi"]
const vehicleTypes = ["Truck","SUV","Sedan"]

const CarRentals = ()=>{
    const serviceProviders = [
        {
            name: "Hertz Car Rental",
            image: "https://images.unsplash.com/photo-1580518324671-c2f0833a3af3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZmFjZSUyMG1hbnxlbnwwfHwwfHx8MA%3D%3D",
            province: "Gauteng",
            suburb: "Sandton",
            type: "Individual",
            insuranceTypes: ["Funeral"],
            experience: "1 Year",
            description: ""
        },
        {
            name: "Avis Car Rental",
            image: "https://media.istockphoto.com/id/1171169127/photo/headshot-of-cheerful-handsome-man-with-trendy-haircut-and-eyeglasses-isolated-on-gray.webp?b=1&s=170667a&w=0&k=20&c=ErOpmNtGDfh1N5xs34-cycSdTso18EvFHEIpcBZC9w0=",
            province: "Gauteng",
            suburb: "Centurion",
            type: "Individual",
            insuranceTypes: ["Vehicle"],
            experience: "Over 5 Years",
            description: ""
        },
        {
            name: "Pace Car Rental",
            image: "https://images.unsplash.com/photo-1605462863863-10d9e47e15ee?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGZhY2UlMjBtYW58ZW58MHx8MHx8fDA%3D",
            province: "Gauteng",
            suburb: "Centurion",
            type: "Individual",
            insuranceTypes: ["Medical"],
            experience: "3 Years",
            description: ""
        },
    ]

    const [agentRating,setAgentRating] = useState(4)
    const [activeView,setActiveView] = useState("List")

    return(
        <Row>
            <Col span={24}>
                <Card style={{width:"97%"}}>
                    <Row gutter={[16,10]}>
                        <Col span={24} style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                            <Space size={12}>
                                <OrderedListOutlined 
                                    style={{
                                        fontSize:"19px",
                                        color: activeView === "List" && "#1195ed",
                                        cursor: "pointer"
                                    }} 
                                    onClick={()=> setActiveView("List")}
                                />
                                <MdOutlineDashboardCustomize 
                                    style={{
                                        fontSize:"19px",
                                        color: activeView === "Grid" && "#1195ed",
                                        cursor: "pointer"
                                    }}                             
                                    onClick={()=> setActiveView("Grid")}
                                />
                            </Space>
                        </Col>
                        <Col span={8}>
                            <Card>
                                <div style={{fontSize:"17px",fontWeight:"bold"}}>
                                    Search Car Rentals
                                </div>
                                <Input 
                                    placeholder="Search By Name" 
                                    size="large"
                                    style={{marginTop:"16px",marginBottom:"16px"}}
                                />
                                <Collapse
                                    size="small" 
                                    items={[
                                        {
                                            key: 1,
                                            label: "Location",
                                            children: displayFilters(locations)
                                        }
                                    ]}
                                    style={{marginBottom:"16px"}}
                                />
                                <Collapse
                                    size="small" 
                                    items={[
                                        {
                                            key: 1,
                                            label: "Vehicle Type",
                                            children: displayFilters(vehicleTypes)
                                        }
                                    ]}
                                    style={{marginBottom:"16px"}}
                                />
                                <Collapse
                                    size="small" 
                                    items={[
                                        {
                                            key: 1,
                                            label: "Vehicle Brand",
                                            children: displayFilters(brands)
                                        }
                                    ]}
                                    style={{marginBottom:"16px"}}
                                />
                                <Collapse
                                    size="small" 
                                    items={[
                                        {
                                            key: 1,
                                            label: "Rental Duration",
                                            children: displayFilters(rentalDuration)
                                        }
                                    ]}
                                    style={{marginBottom:"16px"}}
                                />
                                <div style={{marginBottom:"10px"}}>
                                    Service Rating
                                </div>
                                <Rate 
                                    size="small" 
                                    value={agentRating}
                                    onChange={(value)=> setAgentRating(value)}
                                />
                            </Card>
                        </Col>
                        <Col span={16}>
                            {activeView === "List" ? <ServicesListView serviceProviders={serviceProviders} /> : <ServicesGridView serviceProviders={serviceProviders} /> }
                        </Col>
                    </Row>
                    <Row gutter={[24,24]}>

                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default CarRentals