import axios from "axios"

export const getAllProducts = ()=>{
    return axios.get("https://blufountaininsure.co.za/wp-json/wc/v3/products", {
        params: {
            consumer_key: "ck_a6f1016ee7693c9cc361be0747e31bed18388fa9",
            consumer_secret: "cs_eb61ed162d84879dd70057e7b39d9b70c9c16915",
            per_page: 50
        }
    })
}