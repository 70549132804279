 export const vehicleProducts = [
    {
        "productMaster": [{
          "id": "PR297",
          "productname": "Gold",
          "insurancetype": "Vehicle",
          "marketingname": "Gold Vehicle Product",
          "description": "Gold Vehicle Product",
          "riderproduct": false,
          "status": "Active",
          "creationdate": "2024-02-26"
        }],
        "claimRules": [{
          "id": 1656,
          "claimassessment_mindays": 25,
          "claimsinvestigation_maxdays": 14,
          "repudiation_allowed": "true",
          "payout_taxable": false,
          "parameter_type": "N/A",
          "waiting_period": 0,
          "productid": "PR297"
        }],
        "contractTemplate": [{
          "id": 9890,
          "contractdetails": "contractTemplate",
          "contracttemplatefile": "https://insurehub-files.s3.us-west-2.amazonaws.com/doc_285.png",
          "productid": "PR297"
        }],
        "genericRules": [{
          "id": 5940,
          "productrenewable": "No",
          "policyholdermin_age": 15,
          "policyholdermax_age": 60,
          "maxpercentagediscount": 10,
          "max_numberofdependants": 0,
          "dependantscriteria": "N/A",
          "termpolicy_allowed": false,
          "min_termpolicy_period": 0,
          "max_termpolicy_period": 0,
          "productid": "PR297"
        }],
        "premiumRules": [{
          "id": 7005,
          "premiumsfrequency": "Monthly",
          "premiumscurrency": "USD",
          "allowednoticeperiod": "1 Week",
          "min_premiumcontractlength": "3 Months",
          "premiums_cancelled": "Yes",
          "coverchange": false,
          "mincoveramount": "N/A",
          "maxcoveramount": "N/A",
          "policy_cancelled": "N/A",
          "cancellation_period": "N/A",
          "productid": "PR297"
        }],
        "productTerms": [{
          "id": 5438,
          "description": "Tcs & Cs",
          "termsfile": "https://insurehub-files.s3.us-west-2.amazonaws.com/doc_828.png",
          "productid": "PR297"
        }],
        "coreServices": [
          {
            "id": "VO_Y_JEv5QC-YbL09D6BT",
            "covertype": "Comprehensive",
            "excessamount": "No excess",
            "status": "Active",
            "productid": "PR297",
            "services": {
              "allowedcoveroptions": [
                {
                  "id": "8hmqnPZpSAFAi3yRNpIap",
                  "servicename": "Accidental & Intentional Damage",
                  "category": "Cover",
                  "startdate": "2024-02-26"
                },
                {
                  "id": "a0gn82h7SiIQKfT4WeFNM",
                  "servicename": "Liability (3rd Party)",
                  "category": "Cover",
                  "startdate": "2024-02-26"
                },
                {
                  "id": "Tgth4bYQjVVscQUQNjcjr",
                  "servicename": "Window Glass Cover",
                  "category": "Additional Cover",
                  "startdate": "2024-02-26"
                },
                {
                  "id": "nQhewjDYO5MTdt9tQeqzk",
                  "servicename": "Towing & Storage",
                  "category": "Additional Cover",
                  "startdate": "2024-02-26"
                },
                {
                  "id": "ctaqeAGPZqHj6tHjHvOqQ",
                  "servicename": "Roadside Assistance",
                  "category": "Benefit",
                  "startdate": "2024-02-26"
                }
              ]
            }
          }
        ]
    },
    {
        "productMaster": [{
          "id": "PR297",
          "productname": "Diamond",
          "insurancetype": "Vehicle",
          "marketingname": "Diamond Vehicle Product",
          "description": "Diamond Vehicle Product",
          "riderproduct": false,
          "status": "Active",
          "creationdate": "2024-02-26"
        }],
        "claimRules": [{
          "id": 1656,
          "claimassessment_mindays": 25,
          "claimsinvestigation_maxdays": 14,
          "repudiation_allowed": "true",
          "payout_taxable": false,
          "parameter_type": "N/A",
          "waiting_period": 0,
          "productid": "PR297"
        }],
        "contractTemplate": [{
          "id": 9890,
          "contractdetails": "contractTemplate",
          "contracttemplatefile": "https://insurehub-files.s3.us-west-2.amazonaws.com/doc_285.png",
          "productid": "PR297"
        }],
        "genericRules": [{
          "id": 5940,
          "productrenewable": "No",
          "policyholdermin_age": 15,
          "policyholdermax_age": 60,
          "maxpercentagediscount": 10,
          "max_numberofdependants": 0,
          "dependantscriteria": "N/A",
          "termpolicy_allowed": false,
          "min_termpolicy_period": 0,
          "max_termpolicy_period": 0,
          "productid": "PR297"
        }],
        "premiumRules": [{
          "id": 7005,
          "premiumsfrequency": "Monthly",
          "premiumscurrency": "USD",
          "allowednoticeperiod": "1 Week",
          "min_premiumcontractlength": "3 Months",
          "premiums_cancelled": "Yes",
          "coverchange": false,
          "mincoveramount": "N/A",
          "maxcoveramount": "N/A",
          "policy_cancelled": "N/A",
          "cancellation_period": "N/A",
          "productid": "PR297"
        }],
        "productTerms": [{
          "id": 5438,
          "description": "Tcs & Cs",
          "termsfile": "https://insurehub-files.s3.us-west-2.amazonaws.com/doc_828.png",
          "productid": "PR297"
        }],
        "coreServices": [
          {
            "id": "VO_Y_JEv5QC-YbL09D6BT",
            "covertype": "Comprehensive",
            "excessamount": "No excess",
            "status": "Active",
            "productid": "PR297",
            "services": {
              "allowedcoveroptions": [
                {
                  "id": "8hmqnPZpSAFAi3yRNpIap",
                  "servicename": "Accidental & Intentional Damage",
                  "category": "Cover",
                  "startdate": "2024-02-26"
                },
                {
                  "id": "a0gn82h7SiIQKfT4WeFNM",
                  "servicename": "Liability (3rd Party)",
                  "category": "Cover",
                  "startdate": "2024-02-26"
                },
                {
                  "id": "Tgth4bYQjVVscQUQNjcjr",
                  "servicename": "Window Glass Cover",
                  "category": "Additional Cover",
                  "startdate": "2024-02-26"
                },
                {
                  "id": "nQhewjDYO5MTdt9tQeqzk",
                  "servicename": "Towing & Storage",
                  "category": "Additional Cover",
                  "startdate": "2024-02-26"
                },
                {
                  "id": "ctaqeAGPZqHj6tHjHvOqQ",
                  "servicename": "Roadside Assistance",
                  "category": "Benefit",
                  "startdate": "2024-02-26"
                }
              ]
            }
          }
        ]
    },
]


export const carMakes =  [
  {
      id: 1,
      name: 'Jaguar',
      logo: 'https://www.carlogos.org/car-logos/jaguar-logo.png'
  },
  {
      id: 2,
      name: 'BMW',
      logo: 'https://www.carlogos.org/car-logos/bmw-logo.png'
  },
  {
      id: 3,
      name: 'Ford',
      logo: 'https://www.carlogos.org/car-logos/ford-logo.png'
  },
  {
      id: 4,
      name: 'Honda',
      logo: 'https://www.carlogos.org/car-logos/honda-logo.png'
  },
  {
      id: 5,
      name: 'Hyundai',
      logo: 'https://www.carlogos.org/car-logos/hyundai-logo.png'
  },
  {
      id: 6,
      name: 'Mazda',
      logo: 'https://www.carlogos.org/car-logos/mazda-logo.png'
  },
  {
      id: 7,
      name: 'Nissan',
      logo: 'https://www.carlogos.org/car-logos/nissan-logo.png'
  },
  {
      id: 8,
      name: 'Lexus',
      logo: 'https://www.carlogos.org/car-logos/lexus-logo.png'
  },
  {
      id: 9,
      name: 'Chevrolet',
      logo: 'https://www.carlogos.org/car-logos/chevrolet-logo.png'
  },
  {
      id: 10,
      name: 'Mercedes',
      logo: 'https://www.carlogos.org/car-logos/mercedes-benz-logo.png'
  },
  {
      id: 11,
      name: 'Toyota',
      logo: 'https://www.carlogos.org/car-logos/toyota-logo.png'
  },
  {
      id: 12,
      name: 'Peugeot',
      logo: 'https://www.carlogos.org/car-logos/peugeot-logo.png'
  }
]