import { Col, Row,Card,Image } from "antd"
import { useLocation,useNavigate } from "react-router-dom"
import { MdOutlineClose } from "react-icons/md"
import towingIcon from "../../assets/images/icons/car-breakdown.png"
import panelBeatersIcon from "../../assets/images/icons/damaged-car.png"
import carPartsIcon from "../../assets/images/icons/car-parts.png"
import carRentalIcon from "../../assets/images/icons/car-rental.png"
import damagedGlassIcon from "../../assets/images/icons/car-door.png"
import vehicleInspectionIcon from "../../assets/images/icons/vehicle-inspection.png"

const BookingsModal = ({setSelectedHeader,setSelectedService,setSearchResults})=>{

    const bookingServices = [
        {
            name: "Panel Beaters",
            icon: panelBeatersIcon
        },
        {
            name: "Towing Services",
            icon: towingIcon
        },
        {
            name: "Car Parts",
            icon: carPartsIcon
        },
        {
            name: "Car Rental",
            icon: carRentalIcon
        },
        {
            name: "Glass Repairs",
            icon: damagedGlassIcon
        },
        {
            name: "Car Inspection",
            icon: vehicleInspectionIcon
        },
        {
            name: "Glass Repairs",
            icon: damagedGlassIcon
        },
        {
            name: "Car Inspection",
            icon: vehicleInspectionIcon
        },
    ]

    const navigate = useNavigate()
    const location = useLocation()
    const path = location.pathname

    const handleServiceSelect = (service)=>{
        if(path !== "/"){
            navigate("/")
        }
        setSelectedHeader("")
        setSearchResults("")
        setSelectedService(service)
    }
    
    return(
        <Row justify="center" style={{marginTop:"86px"}}>
            <Col span={18}>
                <Card style={{width:"97%"}}>
                    <Row gutter={[0,16]}>
                        <Col span={24} style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <span style={{fontSize:"16px",fontWeight:"bold"}}>Bookings</span>
                            <MdOutlineClose style={{fontSize:"20px",cursor:"pointer"}} onClick={()=>setSelectedHeader("")} />
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Row gutter={[18,16]}>
                                    {
                                        bookingServices.map((service,index)=>(
                                            <Col span={6} key={index}>
                                                <Card 
                                                    hoverable 
                                                    style={{backgroundColor:"#EBEFF3"}}
                                                    onClick={()=>handleServiceSelect(service.name)}
                                                >
                                                    <div
                                                        style={{display:"flex",justifyContent:"center",marginBottom:"5px"}}
                                                    >
                                                        <Image 
                                                            src={service.icon}
                                                            height={60}
                                                            preview={false}
                                                        />
                                                    </div>
                                                    <div
                                                        className="mb-2"
                                                        style={{display:"flex",justifyContent:"center",fontSize:"16px"}}
                                                    >
                                                        {service.name}
                                                    </div>
                                                </Card>
                                            </Col>                                            
                                        ))
                                    }
                                </Row>                               
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default BookingsModal