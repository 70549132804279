import { useState } from "react"
import { Col, Row, Card, Steps, Button } from "antd"
import PersonalDetails from "./Forms/PersonalDetails";
import NextOfKin from "./Forms/NextOfKin";
import Preferences from "./Forms/Preferences";

const FuneralQuotations = () => {

    const [personalDetails, setPersonalDetails] = useState({
        title: "",
        fullName: "",
        idNumber: "",
        dateOfBirth: "",
        phoneNumber: "",
        email: ""
    })

    const [nextOfKin, setNextOfKin] = useState({
        title: "",
        fullName: "",
        idNumber: "",
        dateOfBirth: "",
        phoneNumber: "",
        email: ""
    })

    const [preferences, setPreferences] = useState({
        burialType: "",
        isTransportRequired: "",
        numberOfVehicles: 0,
        vehicleTypeForMourners: ""
    })


    const steps = [
        {
            title: 'Personal Details',
            content: <PersonalDetails setPersonalDetails={setPersonalDetails} />,
        },
        {
            title: 'Next of Kin',
            content: <NextOfKin setNextOfKin={setNextOfKin} />,
        },
        {
            title: 'Preferences',
            content: <Preferences preferences={preferences} setPreferences={setPreferences} />,
        },
    ];

    const [activeStep, setActiveStep] = useState(0)
    const next = () => {
        setActiveStep(activeStep + 1)
    }
    const prev = () => {
        setActiveStep(activeStep - 1)
    }

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title
    }))

    return (
        <Row>
            <Col span={24}>
                <Card style={{ width: "97%" }}>
                    <Row gutter={[16, 20]}>
                        <Col span={24}>
                            <div style={{ fontSize: "17px", fontWeight: "bold" }}>Request Funeral Quotation</div>
                        </Col>
                        <Col span={24}>
                            <Steps current={activeStep} items={items} />
                            <div
                                style={{ marginTop: "30px" }}
                            >
                                {steps[activeStep].content}
                            </div>
                        </Col>
                        <Col span={24}>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "6px" }}>
                                {activeStep > 0 ? (
                                    <Button
                                        type="primary"
                                        onClick={() => prev()}
                                    >
                                        Previous
                                    </Button>
                                ) : <span></span>}

                                {activeStep === steps.length - 1 ? (
                                    <Button type="primary" >
                                        Request Quotation
                                    </Button>
                                ) : (
                                    <Button type="primary" onClick={() => next()}>
                                        Next
                                    </Button>
                                )
                                }
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default FuneralQuotations