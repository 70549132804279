import { Col, Card, Row,Image } from "antd"
import { MdOutlineClose } from "react-icons/md"

import healthIcon from "../../assets/images/icons/bookings/medical.png"
import BusinessInsuranceIcon from "../../assets/images/icons/bookings/insurance-company.png"
import premiumsIcon from "../../assets/images/icons/bookings/premiums.png"
import policiesIcon from "../../assets/images/icons/bookings/insurance-policy.png"
import consultingIcon from "../../assets/images/icons/bookings/consulting.png"


const VehicleInsuranceModal = ({setSelectedHeader,setSelectedService})=>{

    const vehicleInsuranceServices =     [
        {
            name: "Funeral Quotations",
            icon: policiesIcon
        },
        {
            name: "Medical Quotations",
            icon: healthIcon
        },
        {
            name: "Vehicle Quotations",
            icon: policiesIcon
        },
        {
            name: "Funeral Claims",
            icon: consultingIcon
        },
        {
            name: "Medical Claims",
            icon: BusinessInsuranceIcon
        },
        {
            name: "Vehicle Claims",
            icon: premiumsIcon
        },      
    ]

    const handleServiceSelect = (service)=>{
        setSelectedService(service)
        setSelectedHeader("")
    }

    return(
        <Row justify="center" style={{marginTop:"86px"}}>
            <Col span={18}>
                <Card style={{width:"97%"}}>
                    <Row gutter={[16,16]}>
                        <Col span={24} style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                            <MdOutlineClose style={{fontSize:"20px",cursor:"pointer"}} onClick={()=>setSelectedHeader("")} />
                        </Col>
                        <Col span={8}>
                            <Card style={{backgroundColor:"#c8c8cc",height:"100%"}}>
                                <div style={{fontSize:"18px",fontWeight:"bold",color:"#070133"}}> Insurance Services</div>
                                <div style={{marginTop:"14px",fontSize:"15px"}}>
                                    Discover comprehensive and reliable vehicle insurance services tailored to protect you and your prized assets on the road. Our team of experts understands the importance of safeguarding your vehicles against unexpected accidents, theft, and damage.
                                </div>
                            </Card>
                        </Col>
                        <Col span={16}>
                                <Row gutter={[18,16]}>
                                    {
                                        vehicleInsuranceServices.map((service,index)=>(
                                            <Col span={8} key={index}>
                                                <Card 
                                                    hoverable 
                                                    style={{backgroundColor:"#EBEFF3"}}
                                                    onClick={()=>handleServiceSelect(service.name)}
                                                >
                                                    <div
                                                        style={{display:"flex",justifyContent:"center",marginBottom:"5px"}}
                                                    >
                                                        <Image 
                                                            src={service.icon}
                                                            height={60}
                                                            preview={false}
                                                        />
                                                    </div>
                                                    <div
                                                        className="mb-2"
                                                        style={{display:"flex",justifyContent:"center",fontSize:"16px"}}
                                                    >
                                                        {service.name}
                                                    </div>
                                                </Card>
                                            </Col>                                            
                                        ))
                                    }
                                </Row>                                
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>             
    )
}

export default VehicleInsuranceModal