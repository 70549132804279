import { Row,Col,Button,Input, Space, Select } from "antd"
import * as contentHeader from "../../mock-data/contentHeader"
import { useState,useEffect } from "react"

const DisplayContentHeader = ({selectedService,setSearchResults})=>{
    
    const [header,setHeader] = useState(contentHeader.defaultHeader) 

    useEffect(()=>{
        switch(selectedService){
            // Bookings
            case "Panel Beaters":
                setHeader(contentHeader.panelBeaters)
                break
            case "Towing Services":
                setHeader(contentHeader.towingServices)
                break
            case "Car Parts":
                setHeader(contentHeader.carParts)
                break
            case "Car Rental":
                setHeader(contentHeader.carRentals)
                break
            case "Glass Repairs":
                setHeader(contentHeader.glassRepairs)
                break
            // Burial Services
            case "Palours":
                setHeader(contentHeader.palours)
                break
            case "Repatriation":
                setHeader(contentHeader.repatriatonServices)
                break
            case "Transport Services":
                setHeader(contentHeader.transportServices)
                break
            case "Catering Services":
                setHeader(contentHeader.cateringServices)
                break
            case "Funeral Quotations":
                setHeader(contentHeader.funeralQuotations)
                break
            case "Funeral Supplies":
                setHeader(contentHeader.funeralSupplies)
                break
            case "Photographers":
                setHeader(contentHeader.photographers)
                break
            case "Life Quotations":
                setHeader(contentHeader.lifeQuotations)
                break
            case "Vehicle Quotations":
                setHeader(contentHeader.vehicleQuotations)
                break
            default: 
                setHeader(contentHeader.defaultHeader)
                break
        }        
    },[selectedService])

    const locations = ["Bulawayo", "Harare","Midlands", "Manicaland", "Mashonaland Central", "Mashonaland East", "Mashonaland West", "Mashonaland South", "Mashonaland North"]
    const locationOptions = locations.map((location)=>({
        label: location,
        value: location
    }))
    locationOptions.unshift({label:"All Cities", value: "all"})

    return (
        <Row>
            <Col span={24} style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",marginTop:"100px"}}>
                <div style={{fontSize:"26px",fontWeight:"bold"}}>
                    {header?.title}
                </div>
                <div style={{fontSize:"19px",fontWeight:"bold",marginTop:"20px"}}>
                    {header?.text1}
                </div>
                <div style={{fontSize:"19px",fontWeight:"bold"}}>
                    {header?.text2}
                </div>
                <div style={{marginTop:"50px"}}>
                    {
                        selectedService.includes("Quotations") ? 
                        (
                            <Button 
                            type="primary" 
                            size="large" 
                            style={{fontSize:"15px"}}
                            >
                                {header?.buttonTitle}
                            </Button> 
                        ) : 
                        (
                            <div
                                style={{
                                    display:"flex",
                                    backgroundColor:"#ffb601",
                                    borderRadius: "5px",
                                    padding: "5px"
                                }}
                            >
                                <Space size={5}>
                                    <Input
                                        size="large" 
                                        placeholder="Search By Name" 
                                        style={{width:"260px"}}
                                    />
                                    <Select 
                                        size="large"
                                        placeholder="Search By Location"
                                        options={locationOptions}
                                        style={{width:"260px"}}
                                    />
                                    <Button 
                                        type="primary" 
                                        size="large" 
                                        style={{fontSize:"15px"}}
                                        onClick={()=>setSearchResults([])}
                                    >
                                        {header?.buttonTitle}
                                    </Button>
                                </Space>
                            </div>                                
                        )
                    }

                </div>
            </Col>  
        </Row>
    )
}

export default DisplayContentHeader