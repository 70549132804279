import { Col, Row, Modal, Divider, Card } from "antd"
import "./styles.css"

const QuotationSummaryModal = ({quotationSummaryOpen,closeQuotationSummary})=>{
    
    return(
        <Modal 
            title="Life Insurance Quotation Summary" 
            open={quotationSummaryOpen} 
            onCancel={closeQuotationSummary} 
            width={800}
            footer={false}
            centered
        >
            <div className='quote-wrapper'>
                <Card className='qt-wrapper'>
                    <Row style={{height: '100%'}}>
                        <Col span={12} className='qt-summary'>
                            <span className='title'>Life Insurance Premiums</span>
                            <span className='premiums'>R256.99</span>
                            <div className='cardata'>
                                
                            </div>
                            <span className='premiums-desc'>Subject to the terms of this policy, premiums may change based on your additional cover options.</span>
                            
                        </Col>
                        <Col span={12} className='qt-excess'>
                            <span>Insurance Cover Amount</span>
                            <div className='excess colored'>R250 000</div>
                            <span className='cover-desc'>Subject to the terms of this policy, the cover amount will double in the next 36 months as long as you remain claim free.</span>
                            <span className='bottom-desc'>Please refer to your Policy Schedule for more information on the cover amount that may apply to your policy.</span>
                        </Col>
                    </Row>
                    <Divider />
                    <strong className='colored'>Take note that premiums are subject to change.</strong>
                </Card>
                <div className='qt-btns'>
                    <div className='qt-btn' disabled>Accept Quotation</div>
                    <div className='qt-btn' disabled>Email Quotation</div>
                </div>
            </div>
        </Modal>
    )
}

export default QuotationSummaryModal