import { Row, Col, Input, DatePicker, Select } from "antd"

const PersonalDetails = ({ setPersonalDetails }) => {
    const labelStyle = {
        fontSize: "16px",
        marginBottom: "5px"
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setPersonalDetails((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const titles = [
        {
            label: "Mr",
            value: "Mr"
        },
        {
            label: "Mrs",
            value: "Mrs"
        },
    ]

    return (
        <Row gutter={[26, 16]}>
            <Col span={12}>
                <div style={labelStyle}>Title</div>
                <Select 
                    size="large"
                    options={titles} 
                    defaultValue="Select" 
                    onChange={(value)=>handleChange({
                        target:{
                            value: value,
                            name: "title"
                        }
                    })}
                    style={{width:"100%"}}
                />
            </Col>
            <Col span={12}>
                <div style={labelStyle}>Full Name</div>
                <Input
                    size="large"
                    name="fullName"
                    placeholder="Full Name"
                    onChange={handleChange}
                />
            </Col>
            <Col span={12}>
                <div style={labelStyle}>ID Number</div>
                <Input
                    size="large"
                    name="idNumber"
                    placeholder="ID Number"
                    onChange={handleChange}
                />
            </Col>
            <Col span={12}>
                <div style={labelStyle}>Date of Birth</div>
                <DatePicker
                    size="large"
                    name="dateOfBirth"
                    placeholder="Date of Birth"
                    style={{ width: "100%" }}
                    onChange={handleChange}
                />
            </Col>
            <Col span={12}>
                <div style={labelStyle}>Phone Number</div>
                <Input
                    size="large"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    onChange={handleChange}
                />
            </Col>
            <Col span={12}>
                <div style={labelStyle}>Email</div>
                <Input
                    size="large"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                />
            </Col>
        </Row>
    )
}

export default PersonalDetails