// react imports
import { Routes,Route,BrowserRouter } from "react-router-dom";

// import components
import App from "./App";
import ViewPalourDetails from "./components/Services/BurialServices/Palours/ViewPalourDetails";
import ViewCarRental from "./components/Services/Bookings/CarRental/ViewCarRental";
import ViewPanelBeater from "./components/Services/Bookings/PanelBeaters/ViewPanelBeater";
import ViewCarPartsProvider from "./components/Services/Bookings/CarParts/ViewCarPartsProvider";
import ViewTowingServiceProvider from "./components/Services/Bookings/TowingServices/ViewTowingServiceProvider";
import ViewTransportServiceProvider from "./components/Services/BurialServices/TransportServices/ViewTransportServiceProvider";
import ViewFuneralSuppliesProvider from "./components/Services/BurialServices/FuneralSuppplies/ViewFuneralSuppliesProvider";
import ViewRepatriationServiceProvider from "./components/Services/BurialServices/Repatriation/ViewRepatriationServiceProvider";
import ViewCateringServiceProvider from "./components/Services/BurialServices/CateringServices/ViewCateringServiceProvider";
import ViewGlassRepairsProvider from "./components/Services/Bookings/GlassRepairs/ViewGlassRepairsProvider";

const AppRouter = ()=>{
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/palour" element={<ViewPalourDetails />} />
                <Route path="/car-rental" element={<ViewCarRental />} />
                <Route path="/panel-beater" element={<ViewPanelBeater />} />
                <Route path="/car-parts" element={<ViewCarPartsProvider />} />
                <Route path="/towing-services" element={<ViewTowingServiceProvider />} />
                <Route path="/transport-services" element={<ViewTransportServiceProvider />} />
                <Route path="/funeral-supplies" element={<ViewFuneralSuppliesProvider />} />
                <Route path="/repatriation-services" element={<ViewRepatriationServiceProvider />} />
                <Route path="/catering-services" element={<ViewCateringServiceProvider />} />
                <Route path="/glass-repairs" element={<ViewGlassRepairsProvider />} />

            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter