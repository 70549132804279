import { Row, Col, Input, Select } from "antd"

const NextOfKin = ({ setNextOfKin }) => {
    const labelStyle = {
        fontSize: "16px",
        marginBottom: "5px"
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setNextOfKin((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const titles = [
        {
            label: "Mr",
            value: "Mr"
        },
        {
            label: "Mrs",
            value: "Mrs"
        },
    ]

    const relationships = [
        {
            label: "Brother",
            value: "Brother"
        },
        {
            label: "Sister",
            value: "Sister"
        },
        {
            label: "Mother",
            value: "Mother"
        },
        {
            label: "Father",
            value: "Father"
        },
        {
            label: "Cousin",
            value: "Cousin"
        },
    ]

    return (
        <Row gutter={[26, 16]}>
            <Col span={12}>
                <div style={labelStyle}>Title</div>
                <Select 
                    size="large"
                    options={titles} 
                    defaultValue="Select" 
                    onChange={(value)=>handleChange({
                        target:{
                            value: value,
                            name: "title"
                        }
                    })}
                    style={{width:"100%"}}
                />
            </Col>
            <Col span={12}>
                <div style={labelStyle}>Full Name</div>
                <Input
                    size="large"
                    name="fullName"
                    placeholder="Full Name"
                    onChange={handleChange}
                />
            </Col>
            <Col span={12}>
                <div style={labelStyle}>ID Number</div>
                <Input
                    size="large"
                    name="idNumber"
                    placeholder="ID Number"
                    onChange={handleChange}
                />
            </Col>
            <Col span={12}>
                <div style={labelStyle}>Relationship</div>
                <Select 
                    size="large"
                    options={relationships} 
                    defaultValue="Select" 
                    onChange={(value)=>handleChange({
                        target:{
                            value: value,
                            name: "relationship"
                        }
                    })}
                    style={{width:"100%"}}
                />
            </Col>
            <Col span={12}>
                <div style={labelStyle}>Phone Number</div>
                <Input
                    size="large"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    onChange={handleChange}
                />
            </Col>
            <Col span={12}>
                <div style={labelStyle}>Email</div>
                <Input
                    size="large"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                />
            </Col>
        </Row>
    )
}

export default NextOfKin