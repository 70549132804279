import { Row,Col,Card,Image,Space,Tag,Button } from "antd"
import { BsHeart,BsInfoCircleFill } from "react-icons/bs"
import { MdShoppingCart,MdCircle } from "react-icons/md";

const ProductsListView = ({filteredProducts,startIdx,endIdx,openBookingsDrawer,})=>{

    return(
        <Row gutter={[16, 16]}>
            {
                filteredProducts.slice(startIdx, endIdx).map((product, index) => {
                    // format the description
                    const regex = /<p>|<\/p>|<\\n>/gi;
                    const description = product.description.replace(regex, "")
                    const shortDescription = product.short_description.replace(regex, "")
                    const shortDescriptionArray = shortDescription.split(",")
                    return(
                    <Col span={24} key={index}>
                        <Card
                            hoverable
                        >
                            <Row gutter={[24,16]}>
                                <Col span={9}>
                                    <Image
                                        src={product?.images?.[0]?.src}
                                        width="100%"
                                        height="170px"
                                    />
                                </Col>
                                <Col span={10}>
                                    <div style={{fontWeight:"bold",fontSize:"15px",marginTop:"4px",marginBottom:"7px"}}>
                                        {product.name}
                                    </div>
                                    <div >{description}</div>
                                    {
                                        shortDescriptionArray.map((item,index)=>(
                                            <div key={index} style={{display:"flex",alignItems:"center"}}>
                                                <MdCircle style={{fontSize:"6px",marginRight:"7px",color:"grey"}} />
                                                <span>{item}</span>
                                            </div>
                                        ))
                                    }
                                </Col>
                                <Col span={5}>
                                    <div style={{display:"flex",justifyContent:"flex-end",marginBottom:"16px"}}>
                                        <Space>
                                            <Card
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems:"center",
                                                    backgroundColor: "#0a78bf",
                                                    color: "#ffff",
                                                    fontSize: "13px",
                                                    width:"106px",
                                                    height: "30px"
                                                }}
                                            >
                                                <div>{100 - Math.floor(product.sale_price / product.regular_price * 100)}% OFF</div>
                                            </Card>
                                            <BsHeart style={{ fontSize: "21px" }} />
                                        </Space>
                                    </div> 
                                    <div style={{display:"flex",justifyContent:"flex-end",marginBottom:"10px"}}>
                                        <Space size={13}>
                                            <span>
                                                <span style={{ fontWeight: "bold" }}> $ {product.sale_price}</span>
                                                <span> /km</span>
                                            </span>
                                            <span style={{ color: "grey", textDecoration: "line-through" }}> $ {product.regular_price}</span>
                                            {/* <BsInfoCircleFill style={{ color: "grey", marginTop: "7px" }} /> */}
                                        </Space>
                                    </div>
                                    <div style={{display:"flex",justifyContent:"flex-end",marginBottom:"20px"}}>
                                        <Space size={13}>
                                            <span style={{color:"grey"}}>
                                                <span > Valid Until: </span>
                                                <span > {product.date_on_sale_to?.slice(0,10)}</span>
                                            </span>
                                        </Space>                                                                
                                    </div>
                                    <div style={{display:"flex",justifyContent:"flex-end"}}>
                                        <Button
                                            style={{ width: "100%", color: "#288c4e" }}
                                            icon={<MdShoppingCart />}
                                            onClick={openBookingsDrawer}
                                        >
                                            Book Service
                                        </Button>
                                    </div>

                                </Col>
                            </Row>
                        </Card>
                    </Col>                        
                    )
                })
            }
        </Row>
    )
}

export default ProductsListView