import { Row, Col,Space, Card, Avatar } from "antd"

const ServicesGridView = ({palours})=>{

    return(
        <Row gutter={[16,16]}>
            {
                palours.map((palour,index)=>(
                    <Col span={12} key={index}>
                        <Card hoverable style={{borderLeft:"4px solid #1195ed",backgroundColor:"#fefeff"}}>
                            <Row gutter={[0,22]}>
                                <Col span={24}>
                                    <Space>
                                        <Avatar size={60} src={palour.image}/>
                                        <span>
                                            <div style={{fontSize:"16px",fontWeight:"bold",marginBottom:"1px"}}>{palour.name}</div>
                                            <div style={{fontSize:"16px"}}>{palour.suburb}, {palour.province}</div>
                                        </span>
                                    </Space>
                                </Col>
                                <Col span={24}>
                                    <Row>
                                        <Col span={8}>
                                            <div style={{fontWeight:"bold"}}>Type</div>
                                            <div style={{color:"grey"}}>{palour.type}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div style={{fontWeight:"bold"}}>Qualification</div>
                                            <div style={{color:"grey"}}>{palour.qualification}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div style={{fontWeight:"bold"}}>Experience</div>
                                            <div style={{color:"grey"}}>{palour.experience}</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>   
                ))
            }                
        </Row>
    )
}

export default ServicesGridView