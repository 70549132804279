import { Row, Col,Space, Card, Image, Avatar } from "antd"

const ServicesGridView = ({serviceProviders})=>{

    return(
        <Row gutter={[16,16]}>
            {
                serviceProviders.map((provider,index)=>(
                    <Col span={12} key={index}>
                        <Card hoverable style={{borderLeft:"4px solid #1195ed",backgroundColor:"#fefeff"}}>
                            <Row gutter={[0,22]}>
                                <Col span={24}>
                                    <Space>
                                        <Avatar size={60} src={provider.image}/>
                                        <span>
                                            <div style={{fontSize:"16px",fontWeight:"bold",marginBottom:"1px"}}>{provider.name}</div>
                                            <div style={{fontSize:"16px"}}>{provider.suburb}, {provider.province}</div>
                                        </span>
                                    </Space>
                                </Col>
                                <Col span={24}>
                                    <Row>
                                        <Col span={8}>
                                            <div style={{fontWeight:"bold"}}>Type</div>
                                            <div style={{color:"grey"}}>{provider.type}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div style={{fontWeight:"bold"}}>Insurance Type</div>
                                            <div style={{color:"grey"}}>{provider.type}</div>
                                        </Col>
                                        <Col span={8}>
                                            <div style={{fontWeight:"bold"}}>Experience</div>
                                            <div style={{color:"grey"}}>{provider.experience}</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>   
                ))
            }                
        </Row>
    )
}

export default ServicesGridView