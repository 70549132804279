import { Drawer,Row,Col,Input,Select,DatePicker,TimePicker,Button } from "antd"
import { useState } from "react"
import { labelStyle,options,vehicleMakes,vehicleModels,vehicleColors,contactMethods } from "./mockData"
const {TextArea} = Input

const BookingsDrawer = ({bookingsDrawerOpen,closeBookingsDrawer})=>{

    const [booking,setBooking] = useState({
        name: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setBooking((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    return(
        <Drawer
            title="Make a Booking"
            placement="right"
            open={bookingsDrawerOpen}
            onClose={closeBookingsDrawer}
            width={"35%"}
        >
            <Row gutter={[16,16]}>
                <Col span={12}>
                    <div style={labelStyle}>Full Name</div>
                    <Input
                        name="fullName"
                        placeholder="Full Name"
                        onChange={handleChange}
                    />
                </Col>
                <Col span={12}>
                    <div style={labelStyle}>Phone Number</div>
                    <Input
                        name="phoneNumber"
                        placeholder="Phone Number"
                        onChange={handleChange}
                    />
                </Col>
                <Col span={12}>
                    <div style={labelStyle}>Email</div>
                    <Input
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                    />
                </Col>
                <Col span={12}>
                    <div style={labelStyle}>Preferred Contact Method</div>
                    <Select 
                        options={contactMethods} 
                        placeholder="Select" 
                        onChange={(value)=>handleChange({
                            target:{
                                value: value,
                                name: "VehicleModel"
                            } 
                        })}
                        style={{width:"100%"}}
                    />
                </Col>
                <Col span={12}>
                    <div style={labelStyle}>Vehicle Make</div>
                    <Select 
                        options={vehicleMakes} 
                        placeholder="Select" 
                        onChange={(value)=>handleChange({
                            target:{
                                value: value,
                                name: "vehicleMake"
                            } 
                        })}
                        style={{width:"100%"}}
                    />
                </Col>
                <Col span={12}>
                    <div style={labelStyle}>Vehicle Model</div>
                    <Select 
                        options={vehicleModels} 
                        placeholder="Select" 
                        onChange={(value)=>handleChange({
                            target:{
                                value: value,
                                name: "vehicleModel"
                            } 
                        })}
                        style={{width:"100%"}}
                    />
                </Col>
                <Col span={12}>
                    <div style={labelStyle}>Vehicle Color</div>
                    <Select 
                        options={vehicleColors} 
                        placeholder="Select" 
                        onChange={(value)=>handleChange({
                            target:{
                                value: value,
                                name: "vehicleColor"
                            } 
                        })}
                        style={{width:"100%"}}
                    />
                </Col>
                <Col span={12}>
                    <div style={labelStyle}>License Plate Number</div>
                    <Input
                        name="licensePlateNumber"
                        placeholder="License Plate Number"
                        onChange={handleChange}
                    />
                </Col>
                <Col span={12}>
                    <div style={labelStyle}>Vehicle Location</div>
                    <Input
                        name="vehicleLocation"
                        placeholder="Enter the Vehicle Location"
                        onChange={handleChange}
                    />
                </Col>
                <Col span={12}>
                    <div style={labelStyle}>Preferred Towing Date</div>
                    <DatePicker
                        name="towingDate"
                        placeholder="Preferred Towing Date"
                        style={{ width: "100%" }}
                        onChange={(value)=>handleChange({
                            target: {
                                value: value,
                                name: "towingDate"
                            }
                        })}
                    />
                </Col>
                <Col span={12}>
                    <div style={labelStyle}>Preferred Towing Time</div>
                    <TimePicker
                        placeholder="Preferred Towing Time"
                        style={{ width: "100%" }}
                        onChange={(value)=>handleChange({
                            target: {
                                value: value,
                                name: "towingTime"
                            }
                        })}
                    />
                </Col>
                <Col span={12}>
                    <div style={labelStyle}>Are You Insured</div>
                    <Select 
                        options={options} 
                        placeholder="Select" 
                        onChange={(value)=>handleChange({
                            target:{
                                value: value,
                                name: "hasInsurance"
                            } 
                        })}
                        style={{width:"100%"}}
                    />
                </Col>
                {
                    booking.hasInsurance === "Yes" && (
                        <>
                            <Col span={12}>
                                <div style={labelStyle}>Insurance Provider Name</div>
                                <Input
        
                                    name="insuranceProvider"
                                    placeholder="Enter Insurance Provider Name"
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col span={12}>
                                <div style={labelStyle}>Policy Number</div>
                                <Input
        
                                    name="policyNumber"
                                    placeholder="Policy Number"
                                    onChange={handleChange}
                                />
                            </Col>
                        </>
                    )
                }
                <Col span={24}>
                    <div style={labelStyle}>Provide any additional information or specific requirements</div>
                    <TextArea
                        rows={2}
                        name="additionalInfo"
                        placeholder="Enter Additional Information"
                        onChange={handleChange}
                    />
                </Col>
                <Col span={24} style={{textAlign:"right"}}>
                    <Button type="primary">Complete Booking</Button>
                </Col>
            </Row>
        </Drawer>
    )
}

export default BookingsDrawer