import { useEffect, useState } from "react"
import { Col, Row, Card, Space, Collapse, Checkbox, Rate, Input, Button, Slider, Switch,Segmented } from "antd"
import TopHeader from "../../../../TopHeader"
import {MdList,MdOutlineGridView } from "react-icons/md";
import { getAllProducts } from "../../../../../utils/backendServices";
import ProductsGridView from "./ProductsGridView";
import ProductsListView from "./ProductsListView";

const displayFilters = (filters, handleFilterChange,displayType) => (
    displayType === "full width" ? (
        filters.map((filter, index) => (
            <div style={{ marginBottom: "6px" }} key={index}>
                <Checkbox
                    onChange={(e) => e.target.checked ? handleFilterChange(filter) : handleFilterChange("")}
                >
                    {filter}
                </Checkbox>
            </div>
        ))        
    ) : (
            <Row gutter={[0,7]} >
                {
                    filters.map((filter, index) => (
                        <Col span={12} key={index}>
                            <Checkbox
                                onChange={(e) => e.target.checked ? handleFilterChange(filter) : handleFilterChange("")}
                            >
                                {filter}
                            </Checkbox>                    
                        </Col>
                    ))                     
                }

            </Row>
    )
)

const categories = ["Commercial", "Family", "Luxury", "SUV", "Mini Auto"]
const locations = ["Bulawayo", "Harare", "Midlands", "Manicaland", "Mashonaland Central", "Mashonaland East", "Mashonaland West", "Mashonaland South", "Mashonaland North"]
const transmissions = ["Any","Auto","Manual"]
const bodyStyles = ["Sedan", "Wagon", "Hatchback", "Pickup", "Crossover", "Sports Coupe", "Van", "Coupe"]
const vehicleMakes = ["Toyota","Honda","Ford","Chevrolet","Nissan","Mercedes Benz","Tesla","BMW"]
const vehicleModels = ["Toyota Camry (2022)","Toyota Corolla (2021)","Toyota RAV4 (2023)","Honda Civic (2022)","Ford Mustang (2021)","Honda Accord (2021)","Honda CR-V (2023)","Ford F-150 (2022)",]

const ViewCarRental = () => {

    // define state variables to manipulate content on TopHeader
    const [selectedHeader, setSelectedHeader] = useState("")
    const [isHovered, setIsHovered] = useState(false);
    const [selectedService, setSelectedService] = useState("Car Rental")
    const [searchResults,setSearchResults] = useState("")

    const handleMouseEnter = (header) => {
        setIsHovered(true);
        setSelectedHeader(header)
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const [rating, setRating] = useState(4)
    const [rentalType,setRentalType]= useState("")
    const [pricingRange, setPricingRange] = useState([0, 500])

    // state variable to set active view
    const [activeView,setActiveView] = useState("Grid")

    // make api call and fetch all products from woocommerce
    const [products, setProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState(products)

    useEffect(() => {
        getAllProducts().then((res) => {
            const allProducts = res.data
            const carRentalProducts = allProducts.filter((product) => product?.tags?.[0]?.name === "Hertz Car Rental")
            setProducts(carRentalProducts)
            setFilteredProducts(carRentalProducts)
        }).catch((error) => {
            console.error(error)
        })
    }, [])

    // function to filter products based on selected filter
    const handleFilterChange = (selectedFilter) => {
        if (selectedFilter) {
            const fProducts = products?.filter((product) => product?.categories?.[0]?.name === (selectedFilter + " vehicle").toLowerCase())
            setFilteredProducts(fProducts)
        }
        else {
            setFilteredProducts(products)
        }
    }

    // filter products based on price range
    const handleFilterByPriceRange = (priceRange) => {
        const minPrice = parseFloat(priceRange[0])
        const maxPrice = parseFloat(priceRange[1])
        setPricingRange(priceRange)
        const fProducts = products.filter((product) =>{
            const productPrice = parseFloat(product.sale_price )
            return (productPrice >= minPrice && productPrice <= maxPrice)
        })
        setFilteredProducts(fProducts)            
    }

    // pagination state variables
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage,setItemsPerPage] = useState(4);

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = currentPage * itemsPerPage;

    return (
        <div>
            <TopHeader
                isHovered={isHovered}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                selectedHeader={selectedHeader}
                setSelectedHeader={setSelectedHeader}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
                searchResults= {searchResults}
                setSearchResults = {setSearchResults}
            />
            <Row
                gutter={[24, 16]}
                justify="center"
                style={{ marginTop: "30px", marginBottom: "30px" }}
            >
                <Col span={18}>
                    <Row>
                        <Col span={24}>
                            <Card style={{ width: "97%" }}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <div style={{display:"flex",justifyContent:"flex-end"}}>
                                            <Space size={12}>
                                                <Space style={{marginTop:"5px"}}>
                                                    <MdOutlineGridView 
                                                        style={{
                                                            fontSize:"21px",
                                                            cursor:"pointer",
                                                            color: activeView === "Grid" && "#1195ed",
                                                        }}
                                                        onClick={()=> {
                                                            setActiveView("Grid")
                                                            setItemsPerPage(4)
                                                        }}
                                                    />
                                                    <MdList 
                                                        style={{
                                                            fontSize:"28px",
                                                            cursor:"pointer",
                                                            color: activeView === "List" && "#1195ed",
                                                        }}
                                                        onClick={()=> {
                                                            setActiveView("List")
                                                            setItemsPerPage(2)
                                                        }}
                                                            
                                                    />
                                                </Space>
                                                <Segmented 
                                                    options={["Best Match","Best Sellers","Newest","Best Rated","Trending"]}
                                                    style={{backgroundColor:"#F8f6fb"}}
                                                />                                                
                                            </Space>

                                        </div>
                                    </Col>
                                    <Col span={8} >
                                        <Card style={{ height: "100%" }}>
                                            <div style={{ fontSize: "17px", fontWeight: "bold" }}>
                                                Hertz Car Rentals
                                            </div>
                                            <Input
                                                placeholder="Search By Name"
                                                size="large"
                                                style={{ marginTop: "16px", marginBottom: "16px" }}
                                            />
                                            <div style={{display:"flex",justifyContent:"space-between",marginBottom:"16px"}}>
                                                <span style={{fontSize:"15px"}}>Available Now Only</span>
                                                <Switch />
                                            </div>
                                            <Collapse
                                                size="small"
                                                items={[
                                                    {
                                                        key: 1,
                                                        label: "Rental Type",
                                                        children: (
                                                            <Space>
                                                                <Button
                                                                    type={rentalType==="Any" ? "primary" : "default"}
                                                                    onClick={()=> setRentalType("Any")}
                                                                >
                                                                    Any
                                                                </Button>
                                                                <Button
                                                                    type={rentalType==="Per Day" ? "primary" : "default"}
                                                                    onClick={()=> setRentalType("Per Day")}
                                                                >
                                                                    Per Day
                                                                </Button>
                                                                <Button
                                                                    type={rentalType==="Per Hour" ? "primary" : "default"}
                                                                    onClick={()=> setRentalType("Per Hour")}
                                                                >
                                                                    Per Hour
                                                                </Button>
                                                                
                                                            </Space>
                                                        )
                                                    }
                                                ]}
                                                style={{ marginBottom: "16px" }}
                                            />
                                            <Collapse
                                                size="small"
                                                items={[
                                                    {
                                                        key: 1,
                                                        label: "Categories",
                                                        children: displayFilters(categories, handleFilterChange, "full width")
                                                    }
                                                ]}
                                                style={{ marginBottom: "16px" }}
                                            />
                                            <Collapse
                                                size="small"
                                                items={[
                                                    {
                                                        key: 1,
                                                        label: "Vehicle Makes",
                                                        children: displayFilters(vehicleMakes, handleFilterChange)
                                                    }
                                                ]}
                                                style={{ marginBottom: "16px" }}
                                            />
                                            <Collapse
                                                size="small"
                                                items={[
                                                    {
                                                        key: 1,
                                                        label: "Vehicle Models",
                                                        children: displayFilters(vehicleModels, handleFilterChange)
                                                    }
                                                ]}
                                                style={{ marginBottom: "16px" }}
                                            />
                                            <Collapse
                                                size="small"
                                                items={[
                                                    {
                                                        key: 1,
                                                        label: "Transmission",
                                                        children: displayFilters(transmissions, handleFilterChange, "full width")
                                                    }
                                                ]}
                                                style={{ marginBottom: "16px" }}
                                            />
                                            <Collapse
                                                size="small"
                                                items={[
                                                    {
                                                        key: 1,
                                                        label: "Body Style",
                                                        children: displayFilters(bodyStyles, handleFilterChange)
                                                    }
                                                ]}
                                                style={{ marginBottom: "16px" }}
                                            />
                                            <Collapse
                                                size="small"
                                                items={[
                                                    {
                                                        key: 1,
                                                        label: "Location",
                                                        children: displayFilters(locations, handleFilterChange, "full width")
                                                    }
                                                ]}
                                                style={{ marginBottom: "16px" }}
                                            />
                                            <div style={{ marginBottom: "10px" }}>
                                                Pricing
                                            </div>
                                            <Slider
                                                range
                                                step={10}
                                                min={0}
                                                max={500}
                                                value={pricingRange}
                                                onChange={(value) => handleFilterByPriceRange(value)}
                                            />
                                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                                                <Input
                                                    value={pricingRange[0]}
                                                    onChange={(e) => handleFilterByPriceRange([e.target.value, pricingRange[1]])}
                                                    style={{ width: "20%" }}
                                                />
                                                <Input
                                                    value={pricingRange[1]}
                                                    onChange={(e) => handleFilterByPriceRange([pricingRange[0], e.target.value])}
                                                    style={{ width: "20%" }}
                                                />
                                            </div>
                                            <div style={{ marginBottom: "10px" }}>
                                                Rating
                                            </div>
                                            <Rate
                                                size="small"
                                                value={rating}
                                                onChange={(value) => setRating(value)}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={16}>
                                        {
                                            activeView === "Grid" ?
                                                <ProductsGridView 
                                                    filteredProducts={filteredProducts}
                                                    startIdx={startIdx}
                                                    endIdx={endIdx}
                                                /> : 
                                                <ProductsListView 
                                                    filteredProducts={filteredProducts}
                                                    startIdx={startIdx}
                                                    endIdx={endIdx}
                                                /> 
                                        }
                                        {
                                            filteredProducts.length > 0 && (
                                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: '20px' }}>
                                                    {currentPage !== 1 ? <Button onClick={handlePrevPage}>Previous</Button> : <span></span>}
                                                    <Button onClick={handleNextPage} style={{ marginLeft: '10px' }} disabled={endIdx >= filteredProducts.length}>Next</Button>
                                                </div>
                                            )
                                        }
                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]}>

                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>

    )
}

export default ViewCarRental