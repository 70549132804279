import { Col, Card, Row,Image } from "antd"
import { useNavigate,useLocation } from "react-router-dom"
import { MdOutlineClose } from "react-icons/md"
import cremationIcon from "../../assets/images/icons/burial-services/ashes.png"
import repatriationIcon from "../../assets/images/icons/burial-services/home.png"
import CateringIcon from "../../assets/images/icons/burial-services/waiter.png"
import HearseIcon from "../../assets/images/icons/burial-services/hearse.png"
import ObituaryIcon from "../../assets/images/icons/burial-services/funeral.png"
import SuppliesIcon from "../../assets/images/icons/burial-services/gravestone.png"

const BurialServicesModal = ({setSelectedHeader,setSelectedService,setSearchResults})=>{

    const burialServices = [
        {
            name: "Palours",
            icon: ObituaryIcon
        },
        {
            name: "Transport Services",
            icon: HearseIcon
        },
        {
            name: "Funeral Supplies",
            icon: SuppliesIcon
        },
        {
            name: "Catering Services",
            icon: CateringIcon
        },
        {
            name: "Repatriation",
            icon: repatriationIcon
        },
        {
            name: "Cremation",
            icon: cremationIcon
        },
        {
            name: "Photographers",
            icon: SuppliesIcon
        },
        {
            name: "Funeral Supplies",
            icon: SuppliesIcon
        },
    ]

    const navigate = useNavigate()
    const location = useLocation()
    const path = location.path

    const handleServiceSelect = (service)=>{
        if(path !== "/"){
            navigate("/")
        }
        setSelectedHeader("")
        setSearchResults("")
        setSelectedService(service)
    }

    return(
        <Row justify="center" style={{marginTop:"86px"}}>
            <Col span={18}>
                <Card style={{width:"97%"}}>
                    <Row gutter={[16,16]}>
                        <Col span={24} style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <span style={{fontSize:"16px",fontWeight:"bold"}}>Burial Services</span>
                            <MdOutlineClose style={{fontSize:"20px",cursor:"pointer"}} onClick={()=>setSelectedHeader("")} />
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Row gutter={[18,16]}>
                                    {
                                        burialServices.map((service,index)=>(
                                            <Col span={6} key={index}>
                                                <Card 
                                                    hoverable 
                                                    style={{backgroundColor:"#EBEFF3"}}
                                                    onClick={()=>handleServiceSelect(service.name)}
                                                >
                                                    <div
                                                        style={{display:"flex",justifyContent:"center",marginBottom:"5px"}}
                                                    >
                                                        <Image 
                                                            src={service.icon}
                                                            height={60}
                                                            preview={false}
                                                        />
                                                    </div>
                                                    <div
                                                        className="mb-2"
                                                        style={{display:"flex",justifyContent:"center",fontSize:"16px"}}
                                                    >
                                                        {service.name}
                                                    </div>
                                                </Card>
                                            </Col>                                            
                                        ))
                                    }
                                </Row>                               
                            </Card>

                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>             
    )
}

export default BurialServicesModal