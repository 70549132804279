import { useEffect, useState } from "react"
import { Col, Row, Card, Space, Collapse, Checkbox, Rate, Input, Image, Button, Tag, Slider } from "antd"
import TopHeader from "../../../../TopHeader"
import { BsHeart, BsHeartFill,BsInfoCircleFill } from "react-icons/bs";
import { MdPhotoCamera,MdShoppingCart } from "react-icons/md";
import { getAllProducts } from "../../../../../utils/backendServices";

const displayFilters = (filters,handleFilterChange) => (
    filters.map((filter, index) => (
        <div style={{ marginBottom: "6px" }} key={index}>
            <Checkbox 
                onChange={(e)=> e.target.checked ? handleFilterChange(filter) : handleFilterChange("")}
            >
                {filter}
            </Checkbox>
        </div>
    ))
)

const categories = ["Bus", "Hearse", "Limousine", "Funeral Van", "Family Car"]
const locations = ["Bulawayo", "Harare","Midlands", "Manicaland", "Mashonaland Central", "Mashonaland East", "Mashonaland West", "Mashonaland South", "Mashonaland North"]

const ViewTransportServiceProvider = () => {

    // define state variables to manipulate content on TopHeader
    const [selectedHeader, setSelectedHeader] = useState("")
    const [isHovered, setIsHovered] = useState(false);
    const [selectedService, setSelectedService] = useState("Transport Services")
    const [searchResults,setSearchResults] = useState("")

    const handleMouseEnter = (header) => {
        setIsHovered(true);
        setSelectedHeader(header)
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const [rating, setRating] = useState(4)
    const [pricingRange, setPricingRange] = useState([0, 2000])

    // make api call and fetch all products from woocommerce
    const [products, setProducts] = useState([])
    const [filteredProducts,setFilteredProducts] = useState(products)

    useEffect(() => {
        getAllProducts().then((res) => {
            const allProducts = res.data
            const palourProducts = allProducts.filter((product)=> product?.tags?.[0]?.name === "Doves Funeral Services")
            const palourVehicleProducts = palourProducts?.filter((product)=> product?.categories?.[0]?.name === "bus" || product?.categories?.[0]?.name === "hearse")
            setProducts(palourVehicleProducts)
            setFilteredProducts(palourVehicleProducts)
        }).catch((error) => {
            console.error(error)
        })
    }, [])

    // function to filter products based on selected filter
    const handleFilterChange = (selectedFilter)=>{
        if(selectedFilter){
            const fProducts = products?.filter((product)=> product?.categories?.[0]?.name === selectedFilter.toLowerCase())
            setFilteredProducts(fProducts)            
        }
        else{
            setFilteredProducts(products)
        }
    }

    // filter products based on price range
    const handleFilterByPriceRange = (priceRange) => {
        const minPrice = parseFloat(priceRange[0])
        const maxPrice = parseFloat(priceRange[1])
        setPricingRange(priceRange)
        const fProducts = products.filter((product) =>{
            const productPrice = parseFloat(product.sale_price )
            return (productPrice >= minPrice && productPrice <= maxPrice)
        })
        setFilteredProducts(fProducts)            
    }

    // pagination state variables
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4;
  
    const handleNextPage = () => {
      setCurrentPage(prevPage => prevPage + 1);
    };
  
    const handlePrevPage = () => {
      setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };
  
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = currentPage * itemsPerPage;
    
    return (
        <div>
            <TopHeader
                isHovered={isHovered}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                selectedHeader={selectedHeader}
                setSelectedHeader={setSelectedHeader}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
                searchResults= {searchResults}
                setSearchResults = {setSearchResults}
            />
            <Row
                gutter={[24, 16]}
                justify="center"
                style={{ marginTop: "30px", marginBottom: "30px" }}
            >
                <Col span={18}>
                    <Row>
                        <Col span={24}>
                            <Card style={{ width: "97%" }}>
                                <Row gutter={[16, 10]}>
                                    <Col span={8} >
                                        <Card style={{height:"95%"}}>
                                            <div style={{ fontSize: "17px", fontWeight: "bold" }}>
                                                Doves Funeral Transport Services
                                            </div>
                                            <Input
                                                placeholder="Search By Name"
                                                size="large"
                                                style={{ marginTop: "16px", marginBottom: "16px" }}
                                            />
                                            <Collapse
                                                size="small"
                                                items={[
                                                    {
                                                        key: 1,
                                                        label: "Categories",
                                                        children: displayFilters(categories,handleFilterChange)
                                                    }
                                                ]}
                                                defaultActiveKey={1}
                                                style={{ marginBottom: "16px" }}
                                            />
                                            <Collapse
                                                size="small"
                                                items={[
                                                    {
                                                        key: 1,
                                                        label: "Location",
                                                        children: displayFilters(locations)
                                                    }
                                                ]}
                                                defaultActiveKey={1}
                                                style={{ marginBottom: "16px" }}
                                            />
                                            <div style={{ marginBottom: "10px" }}>
                                                Pricing
                                            </div>
                                            <Slider
                                                range
                                                step={100}
                                                min={0}
                                                max={2000}
                                                value={pricingRange}
                                                onChange={(value) => handleFilterByPriceRange(value)}
                                            />
                                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                                                <Input
                                                    value={pricingRange[0]}
                                                    onChange={(e) => handleFilterByPriceRange([e.target.value, pricingRange[1]])}
                                                    style={{ width: "20%" }}
                                                />
                                                <Input
                                                    value={pricingRange[1]}
                                                    onChange={(e) => handleFilterByPriceRange([pricingRange[0], e.target.value])}
                                                    style={{ width: "20%" }}
                                                />
                                            </div>
                                            <div style={{ marginBottom: "10px" }}>
                                                Rating
                                            </div>
                                            <Rate
                                                size="small"
                                                value={rating}
                                                onChange={(value) => setRating(value)}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={16}>
                                        <Row gutter={[16, 16]}>
                                            {
                                                filteredProducts.slice(startIdx,endIdx).map((product, index) => (
                                                    <Col span={12} key={index}>
                                                        <Card
                                                            hoverable
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                    marginBottom: "7px"
                                                                }}
                                                            >
                                                                <Card
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        backgroundColor: "#0a78bf",
                                                                        color: "#ffff",
                                                                        fontSize: "13px",
                                                                        height: "45px"
                                                                    }}
                                                                >
                                                                    <div>{100 - Math.floor(product.sale_price / product.regular_price * 100)}%</div>
                                                                    <div style={{ marginTop: "-5px" }}>OFF</div>
                                                                </Card>
                                                                <BsHeart style={{ fontSize: "20px" }} />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    marginBottom: "8px"
                                                                }}
                                                            >
                                                                <Image
                                                                    src={product?.images?.[0]?.src}
                                                                    width="60%"
                                                                    height="170px"
                                                                />
                                                            </div>
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        width: "50px",
                                                                        height: "28px",
                                                                        borderRadius: "4px",
                                                                        backgroundColor: "#d0d0d0"
                                                                    }}
                                                                >
                                                                    <MdPhotoCamera style={{ fontSize: "18px", marginRight: "5px" }} />
                                                                    <span style={{ fontWeight: "bold" }}>1</span>
                                                                </div>
                                                            </div>
                                                            <div style={{marginTop:"10px",marginBottom:"10px"}}>
                                                                {product.name} 
                                                            </div>
                                                            <div style={{marginBottom:"8px"}}>
                                                                <Space size={13}>
                                                                    <span style={{fontWeight:"bold"}}> $ {product.sale_price}</span>
                                                                    <span style={{color:"grey",textDecoration:"line-through"}}> $ {product.regular_price}</span>
                                                                    <BsInfoCircleFill style={{color:"grey",marginTop:"7px"}}/>
                                                                </Space>
                                                            </div>
                                                            <div style={{marginBottom:"10px"}}>
                                                                <span style={{color:"grey",marginRight:"10px"}}> 
                                                                    {product.stock_status[0].toUpperCase() + product.stock_status.slice(1)}
                                                                </span>      
                                                                {
                                                                    product?.attributes?.[0]?.options?.map((option)=>(
                                                                        <Tag color="#ebeaeb">
                                                                            <span style={{color:"grey"}}>{option}</span>
                                                                        </Tag>
                                                                    ))
                                                                }
                                                            </div>
                                                            <div >
                                                                <Button 
                                                                    size="large"
                                                                    style={{width:"100%",color:"#288c4e"}}
                                                                    icon={<MdShoppingCart />}
                                                                >
                                                                  Book Now
                                                                </Button>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                        {
                                            filteredProducts.length > 0 && (
                                                <div style={{display:"flex",justifyContent:"space-between", marginTop: '20px'}}>
                                                    {currentPage !== 1 ? <Button onClick={handlePrevPage}>Previous</Button> : <span></span>}
                                                    <Button onClick={handleNextPage} style={{ marginLeft: '10px' }} disabled={endIdx >= filteredProducts.length}>Next</Button>
                                                </div>                                                
                                            )
                                        }
                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]}>

                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>

    )
}

export default ViewTransportServiceProvider