import { Col, Row, Modal, Divider, Card } from "antd"
import './styles.css'

function VehicleQuoteSummary({openPopup,closePopup,car}) {
    
    function sendEmail() {
        let requestObject = {
            car,
            premiums: 'R2,136.42',
            excess: 'R12,987.69'
        }
        closePopup()
    }

    return (
        <Modal 
            title="Car Insurance Quotation Summary" 
            open={openPopup} 
            onCancel={closePopup} 
            width={800}
            footer={false}
            centered
        >
            <div className='quote-wrapper'>
                <Card className='qt-wrapper'>
                    <Row style={{height: '100%'}}>
                        <Col span={12} className='qt-summary'>
                            <span className='title'>Car Insurance Premiums</span>
                            <span className='premiums'>R2,136.42</span>
                            <div className='cardata'>
                                <img src='https://prime.co.za/wp-content/themes/seagull_blue/OnlineApplicationWebClient/assets/svg/luxury%20sedan%20car%20wht.svg' alt='photo' />
                                <span>{`${car.registrationyear} ${car.make} ${car.model}`}</span>
                            </div>
                            <span className='premiums-desc'>Subject to the terms of this policy, your monthly premiums will remain unchanged for the next 24 months as long as you remain claim free.</span>
                        </Col>
                        <Col span={12} className='qt-excess'>
                            <span>Car Insurance Excess</span>
                            <div className='excess colored'>R12,987.69</div>
                            <span className='excess-desc'>Your excess reduces to ZERO over the next 36 months</span>
                            <span className='bottom-desc'>Please refer to your Policy Schedule for more information on the excess that may apply to your policy.</span>
                        </Col>
                    </Row>
                    <Divider />
                    <strong className='colored'>Take note that Excess can reduce to zero over time</strong>
                </Card>
                <div className='qt-btns'>
                    <div className='qt-btn'>Call Me Back</div>
                    <div className='qt-btn' onClick={sendEmail}>Email Quotation</div>
                </div>
            </div>
        </Modal>
    )
}

export default VehicleQuoteSummary