
// content header data
export const defaultHeader = {
    title: "Insurance As A Service",
    text1: "We specialize in delivering unparalleled insurance solutions that cater",
    text2: "to the diverse needs of our valued customers.",
    buttonTitle: "Search Services"
}

export const panelBeaters = {
    title: "Panel Beaters",
    text1: "Explore panel beaters and address all your vehicle body repair needs.",
    text2: "Restore the beauty and structural integrity of your vehicle.",
    buttonTitle: "Search Panel Beaters"
}

export const carRentals = {
    title: "Car Rentals",
    text1: "We strive to make your journey comfortable and hassle-free with our exceptional service",
    text2: "and extensive range of well-maintained vehicles.",
    buttonTitle: "Search Car Rentals"
}

export const towingServices = {
    title: "Towing Services",
    text1: "Vehicle breakdowns and roadside emergencies can be stressful and inconvenient.",
    text2: "Explore our towing service providers to help you get back on track.",
    buttonTitle: "Search Towing Services"
}

export const carParts = {
    title: "Car Parts",
    text1: "We understand the importance of reliable and durable components to keep your vehicle running smoothly.",
    text2: "Explore our car parts service providers with an extensive inventory for all your needs.",
    buttonTitle: "Search Car Parts"
}

export const glassRepairs = {
    title: "Glass Repairs",
    text1: "Explore glass repair services and address all your glass repair needs.",
    text2: "Restore the beauty and integrity of your vehicle.",
    buttonTitle: "Search Glass Repair Services"
}

export const palours = {
    title: "Parlours",
    text1: "We understand the importance of honoring and commemorating the lives of ",
    text2: "loved ones who have passed away.",
    buttonTitle: "Search for Parlours"
}

export const repatriatonServices = {
    title: "Repatriation Services",
    text1: "We understand the complexities and challenges involved in the repatriation process.",
    text2: "We are dedicated to assisting families in safely bringing their loved ones back home.",
    buttonTitle: "Search for Service Providers"
}

export const transportServices = {
    title: "Transport Services",
    text1: "We are dedicated to offering reliable, compassionate, and professional funeral",
    text2: "transport services to help you navigate this challenging time with ease",
    buttonTitle: "Search Transport Services"
}

export const cateringServices = {
    title: "Catering Services",
    text1: "We understand the importance of providing nourishing meals and compassionate",
    text2: "service during times of loss and remembrance.",
    buttonTitle: "Search Catering Services"
}

export const funeralSupplies = {
    title: "Funeral Supplies",
    text1: "We understand the importance of honoring and commemorating the lives of ",
    text2: "loved ones who have passed away.",
    buttonTitle: "Search for Funeral Supplies"
}

export const photographers = {
    title: "Photographers",
    text1: "We understand the importance of capturing the essence and emotions of a funeral or memorial",
    text2: "service allowing you to cherish those moments for years to come.",
    buttonTitle: "Search Photographers"
}

export const funeralQuotations = {
    title: "Funeral Quotations",
    text1: "We specialize in delivering unparalleled insurance solutions that cater",
    text2: "to the diverse needs of our valued customers.",
    buttonTitle: "Request For A Quote"
}

export const lifeQuotations = {
    title: "Life Quotations",
    text1: "We specialize in delivering unparalleled insurance solutions that cater",
    text2: "to the diverse needs of our valued customers.",
    buttonTitle: "Request For A Quote"
}

export const vehicleQuotations = {
    title: "Vehicle Quotations",
    text1: "We specialize in delivering unparalleled insurance solutions that cater",
    text2: "to the diverse needs of our valued customers.",
    buttonTitle: "Request For A Quote"
}