
import { Row, Col, Space, Button } from "antd";
import { Link, useLocation,useNavigate } from "react-router-dom";
import { MdKeyboardArrowUp, MdKeyboardArrowDown, MdSearch } from "react-icons/md";

// import content header component and data
import DisplayContentHeader from "../ContentHeader";

// import modals
import BookingsModal from "../Modals/BookingsModal";
import VehicleInsuranceModal from "../Modals/VehicleInsuranceModal";
import CustomerSupportModal from "../Modals/CustomerSupportModal";
import BurialServicesModal from "../Modals/BurialServicesModal";
import AgentsModal from "../Modals/AgentsModal";

// import drawer
import ProfileManagementDrawer from "../Drawers/ProfileManagement";
import { useState } from "react";

const TopHeader = (props) => {
    const {
        isHovered,
        handleMouseEnter,
        handleMouseLeave,
        selectedHeader,
        setSelectedHeader,
        selectedService,
        setSelectedService,
        searchResults,
        setSearchResults
    } = props

    const headers = ["Bookings", "Insurance Services", "Agents", "Burial Services"]
    
    const location = useLocation()
    const navigate = useNavigate()
    const path = location.pathname

    // state variables to open profile management drawer
    const [drawerOpen,setDrawerOpen] = useState(false)
    const openDrawer = ()=> setDrawerOpen(true)
    const closeDrawer = ()=> setDrawerOpen(false)

    const handleClick = ()=>{
        if(path !="/"){
            navigate("/")
        }
        else{
            openDrawer()
        }
    }

    return (
        <div>
            <Row
                gutter={[24, 16]}
                justify="center"
                style={{ backgroundColor: "#0b073b", color: "#ffffff", position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1 }}
            >
                <Col span={18} style={{ marginTop: "35px", marginBottom: "27px" }}>
                    <Row gutter={[24, 0]} align="middle">
                        <Col span={3} style={{cursor:"pointer"}}>
                            <div onClick={handleClick}>
                                <span style={{ fontSize: "21px", color: "#01cde9" }}>BLUFOUN</span>
                                <span style={{ fontSize: "21px",color:"#ffffff"}}>TAIN</span>
                            </div>
                        </Col>
                        <Col span={11}>
                            {
                                headers.map((header, index) => (
                                    <span style={{ marginRight: "20px" }} key={index}>
                                        <Space
                                            size="small"
                                            style={{ cursor: "pointer" }}
                                            onMouseEnter={() => handleMouseEnter(header)}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <span style={{ fontSize: "17px" }}>{header}</span>
                                            {(selectedHeader === header) ? (
                                                <MdKeyboardArrowUp style={{ fontSize: "21px" }} />
                                            ) : (
                                                <MdKeyboardArrowDown style={{ fontSize: "21px" }} />
                                            )}
                                        </Space>
                                    </span>
                                ))
                            }
                        </Col>
                        <Col span={10}>
                            <Space size="large">
                                <Space
                                    size="small"
                                    style={{ cursor: "pointer" }}
                                    onMouseEnter={() => handleMouseEnter("Support")}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <span style={{ fontSize: "17px" }}>Support</span>
                                    {(isHovered && selectedHeader === "Support") ? (
                                        <MdKeyboardArrowUp style={{ fontSize: "21px" }} />
                                    ) : (
                                        <MdKeyboardArrowDown style={{ fontSize: "21px" }} />
                                    )}
                                </Space>
                                <MdSearch style={{ fontSize: "22px" }} />
                                <Button type="link" style={{ color: "#ffffff", fontSize: "17px" }}>Login</Button>
                                <Button type="primary" shape="round" style={{ color: "#ffffff", fontSize: "15px" }} >Book a demo</Button>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row
                gutter={[24, 16]}
                justify="center"
                style={{ height: "540px", backgroundColor: "#0b073b", color: "#ffffff" }}
            >
                {/** Render content header based on the selected service */}
                {
                    !selectedHeader && (
                        <DisplayContentHeader
                            selectedService={selectedService}
                            setSearchResults={setSearchResults}
                        />
                    )
                }
                {/** Render modals based on hovered header */}
                <Col span={18}>
                    {(selectedHeader === "Bookings") &&
                        <BookingsModal
                            setSelectedHeader={setSelectedHeader}
                            setSelectedService={setSelectedService}
                            setSearchResults={setSearchResults}
                        />

                    }
                    {(selectedHeader === "Insurance Services") &&
                        <VehicleInsuranceModal
                            setSelectedHeader={setSelectedHeader}
                            setSelectedService={setSelectedService}
                        />
                    }
                    {(selectedHeader === "Burial Services") &&
                        <BurialServicesModal
                            setSelectedHeader={setSelectedHeader}
                            setSelectedService={setSelectedService}
                            setSearchResults={setSearchResults}
                        />
                    }
                    {(selectedHeader === "Agents") &&
                        <AgentsModal
                            setSelectedHeader={setSelectedHeader}
                            setSelectedService={setSelectedService}
                        />
                    }
                    {(selectedHeader === "Support") &&
                        <CustomerSupportModal
                            setSelectedHeader={setSelectedHeader}
                            setSelectedService={setSelectedService}
                        />
                    }
                </Col>
            </Row>         
            {/** Drawer */}
            <ProfileManagementDrawer 
                drawerOpen={drawerOpen}
                closeDrawer={closeDrawer}
            />           
        </div>
    )
}

export default TopHeader