import { useEffect, useState } from "react"
import { Col, Row, Card, Space, Collapse, Checkbox, Rate, Input, Button, Slider, Segmented } from "antd"
import TopHeader from "../../../../TopHeader"
import { MdList,MdOutlineGridView } from "react-icons/md";
import { getAllProducts } from "../../../../../utils/backendServices";
import ProductsGridView from "./ProductsGridView";
import ProductsListView from "./ProductsListView";
import BookingsDrawer from "../../../../Drawers/BookingsDrawer";
import CommunicationDrawer from "../../../../Drawers/CommunicationDrawer";

const displayFilters = (filters, handleFilterChange) => (
    filters.map((filter, index) => (
        <div style={{ marginBottom: "6px" }} key={index}>
            <Checkbox
                onChange={(e) => e.target.checked ? handleFilterChange(filter) : handleFilterChange("")}
            >
                {filter}
            </Checkbox>
        </div>
    ))
)

const categories = ["Bus Towing","Motorcycle","Car Towing","Truck Towing"]
const locations = ["Bulawayo", "Harare", "Midlands", "Manicaland", "Mashonaland Central", "Mashonaland East", "Mashonaland West", "Mashonaland South", "Mashonaland North"]

const ViewTowingServiceProvider = () => {

    // define state variables to manipulate content on TopHeader
    const [selectedHeader, setSelectedHeader] = useState("")
    const [isHovered, setIsHovered] = useState(false);
    const [selectedService, setSelectedService] = useState("Towing Services")
    const [searchResults,setSearchResults] = useState("")

    const handleMouseEnter = (header) => {
        setIsHovered(true);
        setSelectedHeader(header)
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const [rating, setRating] = useState(4)
    const [pricingRange, setPricingRange] = useState([0, 500])

    // make api call and fetch all products from woocommerce
    const [products, setProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState(products)
    console.log("filterdProducts: ",filteredProducts)
    useEffect(() => {
        getAllProducts().then((res) => {
            const allProducts = res.data
            const towingServiceProducts = allProducts.filter((product) => product?.tags?.[0]?.name === "FS Towing and Recovery")
            setProducts(towingServiceProducts)
            setFilteredProducts(towingServiceProducts)
        }).catch((error) => {
            console.error(error)
        })
    }, [])

    // function to filter products based on selected filter
    const handleFilterChange = (selectedFilter) => {
        if (selectedFilter) {
            const fProducts = products?.filter((product) => (product?.categories?.[0]?.name).toLowerCase() === selectedFilter.toLowerCase())
            setFilteredProducts(fProducts)
        }
        else {
            setFilteredProducts(products)
        }
    }

    // filter products based on price range
    const handleFilterByPriceRange = (priceRange) => {
        const minPrice = parseFloat(priceRange[0])
        const maxPrice = parseFloat(priceRange[1])
        setPricingRange(priceRange)
        const fProducts = products.filter((product) =>{
            const productPrice = parseFloat(product.sale_price )
            return (productPrice >= minPrice && productPrice <= maxPrice)
        })
        setFilteredProducts(fProducts)            
    }

    // pagination state variables
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage,setItemsPerPage] = useState(4);

    const handleNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = currentPage * itemsPerPage;

    // state variable to set active view
    const [activeView,setActiveView] = useState("Grid")

    // state variable to open bookings drawer
    const [bookingsDrawerOpen,setBookingsDrawerOpen] = useState(false)
    const openBookingsDrawer = ()=> setBookingsDrawerOpen(true)
    const closeBookingsDrawer = ()=> setBookingsDrawerOpen(false)

    const [communicationDrawerOpen,setCommunicationDrawerOpen] = useState(false)
    const openCommunicationDrawer = ()=> setCommunicationDrawerOpen(true)
    const closeCommunicationDrawer = ()=> setCommunicationDrawerOpen(false)

    return (
        <div>
            <TopHeader
                isHovered={isHovered}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                selectedHeader={selectedHeader}
                setSelectedHeader={setSelectedHeader}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
                searchResults= {searchResults}
                setSearchResults = {setSearchResults}
            />
            <Row
                gutter={[24, 16]}
                justify="center"
                style={{ marginTop: "30px", marginBottom: "30px" }}
            >
                <Col span={18}>
                    <Row>
                        <Col span={24}>
                            <Card style={{ width: "97%" }}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <div style={{display:"flex",justifyContent:"flex-end"}}>
                                            <Space size={12}>
                                                <Space style={{marginTop:"5px"}}>
                                                    <MdOutlineGridView 
                                                        style={{
                                                            fontSize:"21px",
                                                            cursor:"pointer",
                                                            color: activeView === "Grid" && "#1195ed",
                                                        }}
                                                        onClick={()=> {
                                                            setActiveView("Grid")
                                                            setItemsPerPage(4)
                                                        }}
                                                    />
                                                    <MdList 
                                                        style={{
                                                            fontSize:"28px",
                                                            cursor:"pointer",
                                                            color: activeView === "List" && "#1195ed",
                                                        }}
                                                        onClick={()=> {
                                                            setActiveView("List")
                                                            setItemsPerPage(4)
                                                        }}
                                                            
                                                    />
                                                </Space>
                                                <Segmented 
                                                    options={["Best Match","Best Sellers","Newest","Best Rated","Trending"]}
                                                    style={{backgroundColor:"#F8f6fb"}}
                                                />                                                
                                            </Space>

                                        </div>
                                    </Col>
                                    <Col span={8} >
                                        <Card style={{ height: "100%" }}>
                                            <div style={{ fontSize: "17px", fontWeight: "bold" }}>
                                                FS Towing and Recovery Services
                                            </div>
                                            <Input
                                                placeholder="Search By Name"
                                                size="large"
                                                style={{ marginTop: "16px", marginBottom: "16px" }}
                                            />
                                            <Collapse
                                                size="small"
                                                items={[
                                                    {
                                                        key: 1,
                                                        label: "Categories",
                                                        children: displayFilters(categories, handleFilterChange)
                                                    }
                                                ]}
                                                defaultActiveKey={1}
                                                style={{ marginBottom: "16px" }}
                                            />
                                            <Collapse
                                                size="small"
                                                items={[
                                                    {
                                                        key: 1,
                                                        label: "Location",
                                                        children: displayFilters(locations, handleFilterChange)
                                                    }
                                                ]}
                                                defaultActiveKey={1}
                                                style={{ marginBottom: "16px" }}
                                            />
                                            <div style={{ marginBottom: "10px" }}>
                                                Pricing
                                            </div>
                                            <Slider
                                                range
                                                step={10}
                                                min={0}
                                                max={500}
                                                value={pricingRange}
                                                onChange={(value) => handleFilterByPriceRange(value)}
                                            />
                                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
                                                <Input
                                                    value={pricingRange[0]}
                                                    onChange={(e) => handleFilterByPriceRange([e.target.value, pricingRange[1]])}
                                                    style={{ width: "20%" }}
                                                />
                                                <Input
                                                    value={pricingRange[1]}
                                                    onChange={(e) => handleFilterByPriceRange([pricingRange[0], e.target.value])}
                                                    style={{ width: "20%" }}
                                                />
                                            </div>
                                            <div style={{ marginBottom: "10px" }}>
                                                Rating
                                            </div>
                                            <Rate
                                                size="small"
                                                value={rating}
                                                onChange={(value) => setRating(value)}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={16}>
                                        {
                                            activeView === "Grid" ?
                                                <ProductsGridView 
                                                    filteredProducts={filteredProducts}
                                                    startIdx={startIdx}
                                                    endIdx={endIdx}
                                                    openBookingsDrawer={openBookingsDrawer}
                                                    openCommunicationDrawer={openCommunicationDrawer}
                                                /> : 
                                                <ProductsListView 
                                                    filteredProducts={filteredProducts}
                                                    startIdx={startIdx}
                                                    endIdx={endIdx}
                                                    openBookingsDrawer={openBookingsDrawer}
                                                    openCommunicationDrawer={openCommunicationDrawer}
                                                /> 
                                        }
                                        {
                                            filteredProducts.length > 0 && (
                                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: '20px' }}>
                                                    {currentPage !== 1 ? <Button onClick={handlePrevPage}>Previous</Button> : <span></span>}
                                                    <Button onClick={handleNextPage} style={{ marginLeft: '10px' }} disabled={endIdx >= filteredProducts.length}>Next</Button>
                                                </div>
                                            )
                                        }
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/** Drawers */}
            <BookingsDrawer 
                bookingsDrawerOpen={bookingsDrawerOpen}
                closeBookingsDrawer={closeBookingsDrawer}
            />
            <CommunicationDrawer 
                communicationDrawerOpen={communicationDrawerOpen}
                closeCommunicationDrawer={closeCommunicationDrawer}
            />
        </div>

    )
}

export default ViewTowingServiceProvider