import { Row,Col,Card,Image,Space,Tag,Button } from "antd"
import { BsHeart,BsInfoCircleFill } from "react-icons/bs"
import { MdPhotoCamera, MdShoppingCart, MdOutlineEmail } from "react-icons/md";

const ProductsGridView = ({filteredProducts,startIdx,endIdx,openBookingsDrawer,openCommunicationDrawer})=>{
    return(
        <Row gutter={[16, 16]}>
            {
                filteredProducts.slice(startIdx, endIdx).map((product, index) => (
                    <Col span={12} key={index}>
                        <Card
                            hoverable
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "7px"
                                }}
                            >
                                <Card
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        backgroundColor: "#0a78bf",
                                        color: "#ffff",
                                        fontSize: "13px",
                                        height: "45px"
                                    }}
                                >
                                    <div>{100 - Math.floor(product.sale_price / product.regular_price * 100)}%</div>
                                    <div style={{ marginTop: "-5px" }}>OFF</div>
                                </Card>
                                <BsHeart style={{ fontSize: "20px" }} />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: "8px"
                                }}
                            >
                                <Image
                                    src={product?.images?.[0]?.src}
                                    width="60%"
                                    height="170px"
                                />
                            </div>
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "50px",
                                        height: "28px",
                                        borderRadius: "4px",
                                        backgroundColor: "#d0d0d0"
                                    }} 
                                >
                                    <MdPhotoCamera style={{ fontSize: "18px", marginRight: "5px" }} />
                                    <span style={{ fontWeight: "bold" }}>1</span>
                                </div>
                                <MdOutlineEmail 
                                    style={{ fontSize: "22px",cursor:"pointer"}} 
                                    onClick={openCommunicationDrawer}
                                />

                            </div>
                            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                {product.name}
                            </div>
                            <div style={{ marginBottom: "8px",display:"flex",justifyContent:"space-between" }}>
                                <Space size={13}>
                                    <span>
                                        <span style={{ fontWeight: "bold" }}> $ {product.sale_price}</span>
                                        <span> /km</span>
                                    </span>
                                    <span style={{ color: "grey", textDecoration: "line-through" }}> $ {product.regular_price}</span>
                                    <BsInfoCircleFill style={{ color: "grey", marginTop: "7px" }} />
                                </Space>
                                <Space size={13}>
                                    <span style={{color:"grey"}}>
                                        <span > Valid Until: </span>
                                        <span > {product.date_on_sale_to?.slice(0,10)}</span>
                                    </span>
                                </Space>                                                                
                            </div>
                            <div style={{ marginBottom: "10px" }}>
                                <span style={{ color: "grey", marginRight: "10px" }}>
                                    {product.stock_status === "instock" && "Available"}
                                </span>
                                {
                                    product?.attributes?.[0]?.options?.map((option) => (
                                        <Tag color="#ebeaeb">
                                            <span style={{ color: "grey" }}>{option}</span>
                                        </Tag>
                                    ))
                                }
                            </div>
                            <div >
                                <Button
                                    size="large"
                                    style={{ width: "100%", color: "#288c4e" }}
                                    icon={<MdShoppingCart />}
                                    onClick={openBookingsDrawer}
                                >
                                    Book Service
                                </Button>
                            </div>
                        </Card>
                    </Col>
                ))
            }
        </Row>
    )
}

export default ProductsGridView