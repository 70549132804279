import { Row, Col,Space, Card } from "antd"
import { MdOutlineLocationOn } from "react-icons/md"
import { Link } from "react-router-dom"

const ServicesListView = ({panelBeaters})=>{

    return(
        <Row gutter={[16,16]}>
            {
                panelBeaters.map((panelBeater,index)=>(
                    <Col span={24} key={index}>
                        <Link to="repatriation-services">
                            <Card hoverable style={{borderLeft:"4px solid #1195ed",backgroundColor:"#fefeff"}}>
                                <Space size="large">
                                    <Card style={{display:"flex",alignItems:"center",backgroundColor:"#35a875",color:"#fefeff",height:"50px"}}>
                                        <span>{panelBeater.province}</span>
                                    </Card>
                                    <span>
                                        <div style={{fontWeight:"bold",fontSize:"16px"}}>{panelBeater.name}</div>
                                        <Space size={5}>
                                            <MdOutlineLocationOn style={{color:"grey",fontSize:"23px"}} />
                                            <span style={{fontSize:"16px",color:"grey"}}>{`${panelBeater.suburb}, ${panelBeater.province}`}</span>
                                        </Space>
                                    </span>
                                </Space>
                                <div style={{marginTop:"10px"}}>
                                    {panelBeater.name} is a highly skilled professional dedicated to restoring the beauty and structural integrity of your vehicle.
                                </div>
                            </Card>                            
                        </Link>
                    </Col>   
                ))
            }                
        </Row>
    )
}

export default ServicesListView