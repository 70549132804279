import { Row, Col } from "antd";
import { useEffect, useState } from "react";

// import top header
import TopHeader from "../TopHeader";

// import services
import SearchPanelBeaters from "../Services/Bookings/PanelBeaters";
import CarRentals from "../Services/Bookings/CarRental";
import TowingServices from "../Services/Bookings/TowingServices";
import CarParts from "../Services/Bookings/CarParts";
import GlassRepairs from "../Services/Bookings/GlassRepairs";
import Palours from "../Services/BurialServices/Palours";
import RepatriationServices from "../Services/BurialServices/Repatriation";
import TransportServices from "../Services/BurialServices/TransportServices"
import CateringServices from "../Services/BurialServices/CateringServices";
import FuneralSupplies from "../Services/BurialServices/FuneralSuppplies";
import FuneralQuotations from "../Services/InsuranceServices/FuneralQuotation";
import Photographers from "../Services/BurialServices/Photographers";
import RequestLifeQuotation from "../Services/InsuranceServices/LifeQuotation";
import RequestVehicleQuotation from "../Services/InsuranceServices/VehicleQuotation";

const TopNavigation = () => {

    // define state variables to manipulate content on TopHeader
    const [selectedHeader, setSelectedHeader] = useState("")
    const [isHovered, setIsHovered] = useState(false);
    const [selectedService,setSelectedService] = useState("")    
    const [searchResults,setSearchResults] = useState("")

    // if routing back to home page from a different page set selectedService

    const handleMouseEnter = (header) => {
        setIsHovered(true);
        setSelectedHeader(header)
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };


    return (
        <div>
            <TopHeader 
                isHovered={isHovered}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                selectedHeader={selectedHeader}
                setSelectedHeader={setSelectedHeader}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
                searchResults= {searchResults}
                setSearchResults = {setSearchResults}
            />
            <Row
                gutter={[24, 16]}
                justify="center"
                style={{ marginTop:"30px",marginBottom:"30px"}}
            >
                <Col span={18}>
                    {/** Render search filters based on selected service */}
                    {/** Bookings */}
                    {(searchResults && selectedService === "Panel Beaters" ) && <SearchPanelBeaters />}
                    {(searchResults && selectedService === "Towing Services") && <TowingServices />}                    
                    {(searchResults && selectedService === "Car Parts") && <CarParts />}
                    {(searchResults && selectedService === "Car Rental") && <CarRentals />}
                    {(searchResults && selectedService === "Glass Repairs") && <GlassRepairs />}

                    {/** Burial Services */}
                    {(searchResults && selectedService === "Palours") && <Palours setSelectedService={selectedService} />}
                    {(searchResults && selectedService === "Repatriation" )&& <RepatriationServices />}
                    {(searchResults && selectedService === "Transport Services") &&  <TransportServices />}
                    {(searchResults && selectedService === "Catering Services") && <CateringServices />}
                    {(searchResults && selectedService === "Funeral Supplies") && <FuneralSupplies />}  
                    {(searchResults && selectedService === "Photographers") && <Photographers />}                            

                    {/** Agents */}
                    {selectedService === "Life Quotations" && <RequestLifeQuotation />}                            
                    {selectedService === "Vehicle Quotations" && <RequestVehicleQuotation />} 
                    {selectedService === "Funeral Quotations" && <FuneralQuotations />}                         
                    
                </Col>


            </Row>
        </div>
    );
};

export default TopNavigation;