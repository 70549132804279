
export const labelStyle = {
    fontSize: "15px",
    marginBottom: "7px",
}

export const options = [
    {
        label: "Yes",
        value: "Yes"
    },
    {
        label: "No",
        value: "No"
    },
]

export const vehicleColors = [
    {
        label: "Red",
        value: "Red"
    },
    {
        label: "Blue",
        value: "Blue"
    },
    {
        label: "Green",
        value: "Green"
    },
    {
        label: "Yellow",
        value: "Yellow"
    },
];

export const vehicleMakes = [
    {
        label: "Toyota",
        value: "Toyota"
    },
    {
        label: "Honda",
        value: "Honda"
    },
    {
        label: "Ford",
        value: "Ford"
    },
    {
        label: "Chevrolet",
        value: "Chevrolet"
    },
];

export const vehicleModels = [
    {
        label: "Camry",
        value: "Camry"
    },
    {
        label: "Accord",
        value: "Accord"
    },
    {
        label: "F-150",
        value: "F-150"
    },
    {
        label: "Silverado",
        value: "Silverado"
    },
];

export const contactMethods = [
    {
        label: "Email",
        value: "Email"
    },
    {
        label: "Phone Call",
        value: "Phone Call"
    },
    {
        label: "SMS",
        value: "SMS"
    },
    {
        label: "Whatsapp",
        value: "Whatsapp"
    },
];
