import { Drawer,Row,Col,Input,Select,DatePicker,TimePicker,Button } from "antd"
import { useState } from "react"
import { labelStyle} from "./mockData"
const {TextArea} = Input

const CommunicationDrawer= ({communicationDrawerOpen,closeCommunicationDrawer})=>{

    const [communicationDetails,setCommunicationDetails] = useState({
        name: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setCommunicationDetails((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    return(
        <Drawer
            title="Contact Service Provider"
            placement="right"
            open={communicationDrawerOpen}
            onClose={closeCommunicationDrawer}
            width={"35%"}
        >
            <Row gutter={[16,16]}>
                <Col span={12}>
                    <div style={labelStyle}>Full Name</div>
                    <Input
                        name="fullName"
                        placeholder="Full Name"
                        onChange={handleChange}
                    />
                </Col>
                <Col span={12}>
                    <div style={labelStyle}>Email Address</div>
                    <Input
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                    />
                </Col>
                <Col span={24}>
                    <div style={labelStyle}>Email Body</div>
                    <TextArea
                        rows={2}
                        name="additionalInfo"
                        placeholder="Email Body"
                        onChange={handleChange}
                    />
                </Col>
                <Col span={24} style={{textAlign:"right"}}>
                    <Button type="primary">Send Email</Button>
                </Col>
            </Row>
        </Drawer>
    )
}

export default CommunicationDrawer