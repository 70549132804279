import { useState } from "react"
import { Col, Row, Card, Select, Input, DatePicker, Radio, Space, Divider, Button } from "antd"
import QuotationSummaryModal from "./QuoationSummaryModal"

const RequestLifeQuotation = () => {

    const labelStyle = {
        fontSize: "16px",
        marginBottom: "5px"
    }

    const [quotation,setQuoatation] = useState({
        title: "",
        fullName: "",
        maritalStatuses: "",
        dateOfBirth: "",
        smokingStatus: "",
        drinksAlcohol: "",
        educationLevel: "",
        grossIncome: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setQuoatation((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const titles = [
        {
            label: "Mr",
            value: "Mr"
        },
        {
            label: "Mrs",
            value: "Mrs"
        },
    ]

    const maritalStatuses = [
        {
            label: "Single",
            value: "Single"
        },
        {
            label: "Married",
            value: "Married"
        },
        {
            label: "Divorced",
            value: "Divorced"
        },
    ]

    const educationLevels = [
        {
            label: "Ordinary Level",
            value: "Ordinary Level"
        },
        {
            label: "Advanced Level",
            value: "Advanced Level"
        },
        {
            label: "National Certificate",
            value: "National Certificate"
        },
        {
            label: "National Diploma",
            value: "National Diploma"
        },
        {
            label: "Undergraduate Degree",
            value: "Undergraduate Degree"
        },
        {
            label: "Postgraduate Degree",
            value: "Postgraduate Degree"
        },
    ]

    // state variable to open quotation summary
    const [quotationSummaryOpen,setQuotationSummaryOpen] = useState(false)
    const openQuotationSummary = ()=> setQuotationSummaryOpen(true)
    const closeQuotationSummary = ()=> setQuotationSummaryOpen(false)

    return (
        <Row>
            <Col span={24}>
                <Card style={{ width: "97%" }}>
                    <Row gutter={[26, 16]}>
                        <Col span={24}>
                            <div style={{ fontSize: "18px", fontWeight: "bold" }}>Request Life Quotation</div>
                        </Col>
                        <Col span={12}>
                            <div style={labelStyle}>Title</div>
                            <Select 
                                size="large"
                                options={titles} 
                                defaultValue="Select" 
                                onChange={(value)=>handleChange({
                                    target:{
                                        value: value,
                                        name: "title"
                                    }
                                })}
                                style={{width:"100%"}}
                            />
                        </Col>
                        <Col span={12}>
                            <div style={labelStyle}>Full Name</div>
                            <Input
                                size="large"
                                name="fullName"
                                value={quotation.fullName}
                                placeholder="Full Name"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12}>
                            <div style={labelStyle}>Date of Birth</div>
                            <DatePicker
                                size="large"
                                name="dateOfBirth"
                                value={quotation.dateOfBirth}
                                placeholder="Date of Birth"
                                style={{ width: "100%" }}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12}>
                            <div style={labelStyle}>Marital Status</div>
                            <Select 
                                size="large"
                                options={maritalStatuses} 
                                defaultValue="Select" 
                                onChange={(value)=>handleChange({
                                    target:{
                                        value: value,
                                        name: "title"
                                    }
                                })}
                                style={{width:"100%"}}
                            />
                        </Col>
                        <Col span={12}>
                            <div style={labelStyle}>Smoking Status</div>
                            <Radio.Group 
                                name="smokingStatus" 
                                value={quotation?.smokingStatus}
                                onChange={handleChange}
                            >
                                <Space style={{marginTop:'6px'}}>
                                    <Radio value="Smoker">Smoker</Radio>
                                    <Radio value="Non-Smoker">Non Smoker</Radio>                                    
                                </Space>
                            </Radio.Group>
                        </Col>
                        <Col span={12}>
                            <div style={labelStyle}>Do You Drink Alcohol</div>
                            <Radio.Group 
                                name="drinksAlcohol" 
                                value={quotation?.drinksAlcohol}
                                onChange={handleChange}
                            >
                                <Space style={{marginTop:'6px'}}>
                                    <Radio value="Yes">Yes</Radio>
                                    <Radio value="No">No</Radio>                                    
                                </Space>
                            </Radio.Group>
                        </Col>
                        <Col span={12}>
                            <div style={labelStyle}>Highest Level of Education</div>
                            <Select 
                                size="large"
                                options={educationLevels} 
                                defaultValue="Select" 
                                onChange={(value)=>handleChange({
                                    target:{
                                        value: value,
                                        name: "educationLevel"
                                    }
                                })}
                                style={{width:"100%"}}
                            />
                        </Col>
                        <Col span={12}>
                            <div style={labelStyle}>Monthly Gross Income</div>
                            <Input
                                size="large"
                                name="grossIncome"
                                value={quotation.grossIncome}
                                placeholder="Monthly Gross Income"
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={24}>
                            <Divider/>
                            <div style={{display:"flex",justifyContent:"flex-end"}}>
                                <Button 
                                    type="primary"
                                    onClick={openQuotationSummary}
                                >
                                    Request Quotation
                                </Button>                                
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            {/** Quotation Summary */}
            <QuotationSummaryModal 
                quotationSummaryOpen={quotationSummaryOpen}
                closeQuotationSummary={closeQuotationSummary}
            />
        </Row>
    )
}

export default RequestLifeQuotation