import { Row,Col,Card,Image,Space,Tag,Button, Divider, Tabs, Radio, Switch } from "antd"
import { BsHeart,BsSpeedometer2,BsHeartFill,BsDownload } from "react-icons/bs"
import { MdShoppingCart,MdDirectionsCar, MdStar } from "react-icons/md";
import { TbManualGearbox } from "react-icons/tb"

const ProductsListView = ({filteredProducts,startIdx,endIdx,openBookingsDrawer,})=>{
    
    const tabItems = [
        {
            key: 0,
            label: <span style={{color:"grey"}}>Rent Details</span>,
            children: (
                <Row gutter={[24,20]}>
                    <Col span={24}>
                        <Image 
                            src="https://www.google.com/maps/d/thumbnail?mid=1tWDCTO_cQIDD_XqSmqdI98Ki8dY&hl=en_US"
                            width="100%"
                            height="200px"
                            preview={false}
                        />
                    </Col>
                    <Col span={12} style={{borderRight:"1px solid grey"}}>
                        <div style={{marginBottom:"5px"}}>PICK-UP DATE & TIME</div>
                        <Card style={{display:"flex",height:"35px",alignItems:"center",backgroundColor:"#e7e9eb",color:"grey"}}>
                            <span>07/02/2024 1400pm</span>
                        </Card>
                        <div style={{marginTop:"17px",marginBottom:"5px"}}>DROP-OFF DATE & TIME</div>
                        <Card style={{display:"flex",height:"35px",alignItems:"center",backgroundColor:"#e7e9eb",color:"grey"}}>
                            <span>08/02/2024 1400pm</span>
                        </Card>
                        <div style={{marginTop:"12px",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <span>Extra Time</span>
                            <Switch defaultValue={true} size="small"/>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={{display:"flex",justifyContent:"space-between",marginBottom:"10px"}}>
                            <Space>
                                <span>INSURANCE</span>
                            </Space>
                            <span style={{fontWeight:"bold"}}>$28.00</span>
                        </div>
                        <div style={{display:"flex",justifyContent:"space-between",marginBottom:"7px"}}>
                            <Radio>No Insurance</Radio>
                            <span style={{fontWeight:"bold"}}>$0.00</span>
                        </div>
                        <div style={{display:"flex",justifyContent:"space-between",marginBottom:"7px"}}>
                            <Radio checked>Protection</Radio>
                            <span style={{fontWeight:"bold"}}>$28.00</span>
                        </div>
                        <div style={{display:"flex",justifyContent:"space-between",marginBottom:"10px"}}>
                            <Radio>Liability</Radio>
                            <span style={{fontWeight:"bold"}}>$14.00</span>
                        </div>
                        <div style={{display:"flex",justifyContent:"space-between",marginBottom:"10px"}}>
                            <span>Sales Taxes</span>
                            <span style={{fontWeight:"bold"}}>$16.00</span>
                        </div>
                        <div style={{display:"flex",justifyContent:"space-between",marginBottom:"10px"}}>
                            <span style={{fontWeight:"bold"}}>Total Price</span>
                            <span style={{fontWeight:"bold"}}>$120.00</span>
                        </div>
                    </Col>
                    <Col span={24}>
                        <Card style={{backgroundColor:"#0194fc",color:"#ffff"}}>
                            <Row gutter={[26,16]}>
                                <Col span={12}>
                                    <div> Book Vehicle</div>
                                    <div>MERCEDES- AMG GLE</div>
                                </Col>
                                <Col span={12}>
                                    <div> Free Booking</div>
                                    <div>10 minutes</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            )
        },
        {
            key: 1,
            label: <span style={{color:"grey"}}>Vehicle Info</span>,
            children: "2"
        },
        {
            key: 4,
            label: <span style={{color:"grey"}}>Documents</span>,
            children: "2"
        },
    ]
    return(
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <Row gutter={[16, 16]}>
                    {
                        filteredProducts.slice(startIdx, endIdx).map((product, index) => {
                            // format the description
                            const regex = /<p>|<\/p>|<\\n>/gi;
                            const description = product.description.replace(regex, "")
                            const shortDescription = product.short_description.replace(regex, "")
                            const shortDescriptionArray = shortDescription.split(",")
                            return(

                                <Col span={24} key={index}>
                                    <Card
                                        hoverable
                                    >
                                        <Row gutter={[24,8]}>
                                            <Col span={24}>
                                                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                                    <Space>
                                                        <Tag style={{width:"60px"}}>
                                                            <MdStar style={{color:"gold",fontSize:"13px",marginRight:"4px"}}/>
                                                            <span>4.7</span>
                                                        </Tag>
                                                        <Tag color="green">
                                                            Available Now
                                                        </Tag>
                                                        <span>790m</span>
                                                    </Space>
                                                    <BsHeart />
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Image
                                                        src={product?.images?.[0]?.src}
                                                        width="60%"
                                                        height="135px"
                                                    />
                                                </div>                                            
                                            </Col>
                                            <Col span={24}>
                                                <span style={{color:"grey"}}>{product.name.toUpperCase()}</span>
                                            </Col>
                                            <Col span={24}>
                                                <div style={{display:"flex",justifyContent:"space-between"}}>
                                                    <span style={{fontWeight:"bold"}}>{description}</span>
                                                    <div>
                                                        <span style={{fontWeight:"bold"}}>$ {product.sale_price}</span>
                                                        <span style={{color:"grey"}}> /hour</span>                                                        
                                                    </div>

                                                </div>
                                            </Col>
                                            
                                            <Col span={24}>
                                                <Divider style={{marginTop:"10px"}}/>
                                                <div style={{display:"flex",justifyContent:"space-between",marginTop:"-10px"}}>
                                                    <Space>
                                                        <MdDirectionsCar style={{color:"#01cde9",fontSize:"24px"}}/>
                                                        <span style={{color:"grey"}}>Hatchback</span>
                                                    </Space>
                                                    <Space>
                                                        <TbManualGearbox style={{color:"#01cde9",fontSize:"22px"}}/>
                                                        <span style={{color:"grey"}}>Manual</span>
                                                    </Space>
                                                    <Space>
                                                        <BsSpeedometer2 style={{color:"#01cde9",fontSize:"22px"}}/>
                                                        <span style={{color:"grey"}}>Diesel</span>
                                                    </Space>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>                        
                            )
                        })
                    }
                </Row>
            </Col>
            <Col span={12}>
                <Card hoverable>
                    <Row gutter={[24,8]}>
                        <Col span={12}>
                            <Image
                                src={filteredProducts?.[3]?.images?.[0]?.src}
                                width="100%"
                                height="135px"
                            />
                        </Col>
                        <Col span={12}>
                            <Image
                                src={filteredProducts?.[3]?.images?.[0]?.src}
                                width="100%"
                                height="135px"
                            />
                        </Col>
                        <Col span={24}>
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                                <span style={{color:"grey"}}>MERCEDES- AMG GLE</span>
                                <BsHeartFill style={{color:"darkred"}}/>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                                <span style={{fontWeight:"bold"}}>MERCEDES- AMG GLE</span>
                                <div>
                                    <span style={{fontWeight:"bold"}}>$ 28 </span>
                                    <span style={{color:"grey"}}> /hour</span>                                                        
                                </div>

                            </div>
                        </Col>
                        <Col span={24}>
                            <Tabs items={tabItems} />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default ProductsListView