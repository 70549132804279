import { Col, Row,Card,Image } from "antd"
import { MdOutlineClose } from "react-icons/md"
import healthIcon from "../../assets/images/icons/bookings/medical.png"
import BusinessInsuranceIcon from "../../assets/images/icons/bookings/insurance-company.png"
import premiumsIcon from "../../assets/images/icons/bookings/premiums.png"
import policiesIcon from "../../assets/images/icons/bookings/insurance-policy.png"
import consultingIcon from "../../assets/images/icons/bookings/consulting.png"

const AgentsModal = ({setSelectedHeader,setSelectedService})=>{

    const agentServices =     [
        {
            name: "Funeral Quotations",
            icon: policiesIcon
        },
        {
            name: "Life Quotations",
            icon: consultingIcon
        },
        {
            name: "Medical Quotations",
            icon: healthIcon
        },
        {
            name: "Vehicle Quotations",
            icon: policiesIcon
        },
        {
            name: "Property Quotations",
            icon: BusinessInsuranceIcon
        },
        {
            name: "Business Insurance",
            icon: BusinessInsuranceIcon
        },
        {
            name: "Farmers Quotation",
            icon: premiumsIcon
        }, 
        {
            name: "Business Insurance",
            icon: premiumsIcon
        },         
    ]

    const handleServiceSelect = (service)=>{
        setSelectedService(service)
        setSelectedHeader("")
    }
    
    return(
        <Row justify="center" style={{marginTop:"86px"}}>
            <Col span={18}>
                <Card style={{width:"97%"}}>
                    <Row gutter={[0,16]}>
                        <Col span={24} style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <span style={{fontSize:"16px",fontWeight:"bold"}}>Agents Modal</span>
                            <MdOutlineClose style={{fontSize:"20px",cursor:"pointer"}} onClick={()=>setSelectedHeader("")} />
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Row gutter={[18,16]}>
                                    {
                                        agentServices.map((service,index)=>(
                                            <Col span={6} key={index}>
                                                <Card 
                                                    hoverable 
                                                    style={{backgroundColor:"#EBEFF3"}}
                                                    onClick={()=>handleServiceSelect(service.name)}
                                                >
                                                    <div
                                                        style={{display:"flex",justifyContent:"center",marginBottom:"5px"}}
                                                    >
                                                        <Image 
                                                            src={service.icon}
                                                            height={60}
                                                            preview={false}
                                                        />
                                                    </div>
                                                    <div
                                                        className="mb-2"
                                                        style={{display:"flex",justifyContent:"center",fontSize:"16px"}}
                                                    >
                                                        {service.name}
                                                    </div>
                                                </Card>
                                            </Col>                                            
                                        ))
                                    }
                                </Row>                               
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default AgentsModal