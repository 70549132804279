import { Row, Col,Space, Card } from "antd"
import { MdOutlineLocationOn } from "react-icons/md"
import { Link } from "react-router-dom"

const ServicesListView = ({serviceProviders})=>{

    return(
        <Row gutter={[16,16]}>
            {
                serviceProviders.map((provider,index)=>(
                    <Col span={24} key={index}>
                        <Link to="/towing-services">
                            <Card hoverable style={{borderLeft:"4px solid #1195ed",backgroundColor:"#fefeff"}}>
                                <Space size="large">
                                    <Card style={{display:"flex",alignItems:"center",backgroundColor:"#35a875",color:"#fefeff",height:"50px"}}>
                                        <span>{provider.province}</span>
                                    </Card>
                                    <span>
                                        <div style={{fontWeight:"bold",fontSize:"16px"}}>{provider.name}</div>
                                        <Space size={5}>
                                            <MdOutlineLocationOn style={{color:"grey",fontSize:"23px"}} />
                                            <span style={{fontSize:"16px",color:"grey"}}>{`${provider.suburb}, ${provider.province}`}</span>
                                        </Space>
                                    </span>
                                </Space>
                                <div style={{marginTop:"10px"}}>
                                    {provider.name} offers a range of towing services to help you get back on track.
                                </div>
                            </Card>                            
                        </Link>

                    </Col>   
                ))
            }                
        </Row>
    )
}

export default ServicesListView