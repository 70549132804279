import { Row, Col,Space, Card } from "antd"
import { MdOutlineLocationOn } from "react-icons/md"

const ServicesListView = ({palours})=>{

    return(
        <Row gutter={[16,16]}>
            {
                palours.map((palour,index)=>(
                    <Col span={24} key={index}>
                        <Card hoverable style={{borderLeft:"4px solid #1195ed",backgroundColor:"#fefeff"}}>
                            <Space size="large">
                                <Card style={{display:"flex",alignItems:"center",backgroundColor:"#35a875",color:"#fefeff",height:"50px"}}>
                                    <span>{palour.province}</span>
                                </Card>
                                <span>
                                    <div style={{fontWeight:"bold",fontSize:"16px"}}>{palour.name}</div>
                                    <Space size={5}>
                                        <MdOutlineLocationOn style={{color:"grey",fontSize:"23px"}} />
                                        <span style={{fontSize:"16px",color:"grey"}}>{`${palour.suburb}, ${palour.province}`}</span>
                                    </Space>
                                </span>
                            </Space>
                            <div style={{marginTop:"10px"}}>
                                {palour.name} provides photography services that are designed to honor and preserve the memories of your loved ones during this solemn time.
                            </div>
                        </Card>
                    </Col>   
                ))
            }                
        </Row>
    )
}

export default ServicesListView