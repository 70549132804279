import { Col, Row,Card, Button, Space } from "antd"
import { MdOutlineClose,MdOutlineFileDownload, } from "react-icons/md"
import { FaGithub } from "react-icons/fa";
 
const CustomerSupportModal = ({setSelectedHeader})=>{
    return(
        <Row justify="center" style={{marginTop:"86px"}}>
            <Col span={18}>
                <Card style={{width:"98%"}}>
                    <Row gutter={[16,16]}>
                        <Col span={24} style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <span style={{fontSize:"16px",fontWeight:"bold"}}>Support</span>
                            <MdOutlineClose style={{fontSize:"20px",cursor:"pointer"}} onClick={()=>setSelectedHeader("")} />
                        </Col>
                        <Col span={12}>
                            <Card style={{backgroundColor:"#c8c8cc"}}>
                                <div style={{fontSize:"18px",fontWeight:"bold",color:"#070133"}}>Get all the support you need</div>
                                <div style={{marginTop:"10px"}}>
                                    Get your technical questions answered through our online documentation, get in touch with one of our support reps, or deep-dive with one of our consultants.
                                </div>
                                <div style={{textAlign:"center", marginTop:"12px"}}>
                                    <Button type="primary" shape="round">Explore Documentation</Button>
                                </div>
                                <div style={{marginTop:"15px",fontSize:"18px",fontWeight:"bold",color:"#070133"}}>
                                    More Resources
                                </div>
                                <div style={{marginTop:"8px"}}>
                                    <Space>
                                        <MdOutlineFileDownload style={{fontSize:"22px"}}/>
                                        <span>Product Downloads</span>
                                    </Space>
                                </div>
                                <div style={{marginTop:"8px"}}>
                                    <Space>
                                        <FaGithub style={{fontSize:"22px"}}/>
                                        <span>Github Repositories</span>
                                    </Space>
                                </div>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card>
                                <Row gutter={[18,16]}>
                                    <Col span={12}>
                                        <div style={{fontSize:"18px",fontWeight:"bold",color:"#070133"}}>Services</div>
                                        <div style={{marginTop:"14px",color:"#070133",fontSize:"15px",fontWeight:"bold"}}>
                                            Professional Services
                                        </div>
                                        <div style={{marginTop:"4px"}}>
                                            Advisory, training, and monitoring assessments.
                                        </div>
                                        <div style={{marginTop:"14px",color:"#070133",fontSize:"15px",fontWeight:"bold"}}>
                                            Managed Services
                                        </div>
                                        <div style={{marginTop:"4px"}}>
                                            Advisory, training, and monitoring assessments.
                                        </div>
                                        <div style={{marginTop:"14px",color:"#070133",fontSize:"15px",fontWeight:"bold"}}>
                                            Online Courses
                                        </div>
                                        <div style={{marginTop:"4px"}}>
                                            See all online training modules
                                        </div>
                                        
                                    </Col>
                                    <Col span={12}>
                                        <div style={{fontSize:"18px",fontWeight:"bold",color:"#070133"}}>Documentation</div>
                                        <div style={{marginTop:"14px",color:"#070133",fontSize:"15px",fontWeight:"bold"}}>
                                            Product Guides
                                        </div>
                                        <div style={{marginTop:"4px"}}>
                                            User guides to all of the available products
                                        </div>
                                        <div style={{marginTop:"14px",color:"#070133",fontSize:"15px",fontWeight:"bold"}}>
                                            What's New
                                        </div>
                                        <div style={{marginTop:"4px"}}>
                                            Read the latest product updates.
                                        </div>
                                    </Col>
                                </Row>                                
                            </Card>

                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default CustomerSupportModal