import { useState } from "react"
import { Col, Row, Card } from "antd"
import "./styles.css"
import { MdOutlineDone, MdOutlineClose } from "react-icons/md"
import { vehicleProducts } from "./data/mockData"
import VehicleQuotationModal from "./VehicleQuotationModal"
import VehicleQuoteSummary from "./VehicleQuoteSummary"

const RequestVehicleQuotation = () => {
    
    const [products,setProducts] = useState(vehicleProducts)

    /**
     * Defining the modal for the quotations
     */
    const [modalOpen, setModalOpen] = useState(false)
    const [quoteModalOpen, setQuoteModalOpen] = useState(false)
    const [car, setCar] = useState({})

    async function showQuoteSummary(vehicle) {
        await setCar(vehicle)
        setModalOpen(false)
        setQuoteModalOpen(true)
    }
    return (
        <Row>
            <Col span={24}>
                <Card style={{ width: "97%" }}>
                    <Row gutter={[26, 16]}>
                        <Col span={24}>
                            <div style={{ fontSize: "18px", fontWeight: "bold" }}>Request Vehicle Quotation</div>
                        </Col>
                        <Col span={24}>
                            <Row className='qt-header-main'>
                                <Col span={4} className='qt-header-main-content'>
                                    <strong>Vehicle Product</strong>
                                </Col>
                                <Col span={8} className='qt-header-main-content'>
                                    <strong>Cover</strong>
                                </Col>
                                <Col span={6} className='qt-header-main-content gray'>
                                    <strong>Additional / Optional Cover</strong>
                                </Col>
                                <Col span={4} className='qt-header-main-content'>
                                    <strong>Benefits</strong>
                                </Col>
                                <Col span={2} className='qt-header-main-content'></Col>
                            </Row>
                            <Row className='qt-header'>
                                <Col span={4} className='qt-header-top'>
                                    <Row className='qt-row'>
                                        <Col span={12} className='qt-header-content'>
                                            <span>Product Name</span>
                                        </Col>
                                        <Col span={12} className='qt-header-content gray'>
                                            <span>Type Of Cover</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8} className='qt-header-top'>
                                    <Row className='qt-row'>
                                        <Col span={6} className='qt-header-content navy'>
                                            <span>Accidental Damage</span></Col>
                                        <Col span={6} className='qt-header-content navy'>
                                            <span>Theft &amp; Hijack</span>
                                        </Col>
                                        <Col span={6} className='qt-header-content navy'>
                                            <span>Acts of Nature</span>
                                        </Col>
                                        <Col span={6} className='qt-header-content navy'>
                                            <span>Liability (3rd Party)</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6} className='qt-header-top'>
                                    <Row className='qt-row'>
                                        <Col span={8} className='qt-header-content navy'>
                                            <span>Window Glass Cover</span>
                                        </Col>
                                        <Col span={8} className='qt-header-content navy'>
                                            <span>Optional Car Hire</span>
                                        </Col>
                                        <Col span={8} className='qt-header-content navy'>
                                            <span>Towing &amp; Storage</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={4} className='qt-header-top'>
                                    <Row className='qt-row'>
                                        <Col span={12} className='qt-header-content navy'>
                                            <span>Roadside Assistance</span>
                                        </Col>
                                        <Col span={12} className='qt-header-content navy'>
                                            <span>Panel Beating</span>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={2} className='qt-header-top'>
                                    <Row className='qt-row'>
                                        <Col span={24} className='qt-header-content gray'>
                                            <span>Action</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {products.map((product,index) => (
                                <Row className='product' key={index}>
                                    <Col span={2} className='productname productcover'>
                                        <span>{product?.productMaster?.[0]?.productname}</span>
                                    </Col>
                                    <Col span={2} className='qt-header-top'>
                                        {product?.coreServices.map((service,index) => (
                                            <Row className='qt-row covertype' key={index}>
                                                <Col span={24} className='qt-header-content gray'>
                                                    <span>{service.covertype}</span>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                    <Col span={8} className='qt-header-top'>
                                        {product?.coreServices.map((service,index) => (
                                            <Row className='qt-row covertype' key={index}>
                                                <Col span={6} className='qt-header-content navy'>
                                                    <span>
                                                        {service?.services?.allowedcoveroptions.find(option => option.servicename === 'Accidental & Intentional Damage') ? <MdOutlineDone size={16} /> : <MdOutlineClose size={16} />}
                                                    </span>
                                                </Col>
                                                <Col span={6} className='qt-header-content navy'>
                                                    <span>
                                                        {service?.services?.allowedcoveroptions.find(option => option.servicename === 'Theft & Hijack') ? <MdOutlineDone size={16} /> : <MdOutlineClose size={16} />}
                                                    </span>
                                                </Col>
                                                <Col span={6} className='qt-header-content navy'>
                                                    <span>
                                                        {service?.services?.allowedcoveroptions.find(option => option.servicename === 'Acts of Nature') ? <MdOutlineDone size={16} /> : <MdOutlineClose size={16} />}
                                                    </span>
                                                </Col>
                                                <Col span={6} className='qt-header-content navy'>
                                                    <span>
                                                        {service?.services?.allowedcoveroptions.find(option => option.servicename === 'Liability (3rd Party)') ? <MdOutlineDone size={16} /> : <MdOutlineClose size={16} />}
                                                    </span>
                                                </Col>
                                            </Row>
                                        ))}

                                    </Col>
                                    <Col span={6} className='qt-header-top'>
                                        {product?.coreServices.map((service,index) => (
                                            <Row className='qt-row covertype' key={index}>
                                                <Col span={8} className='qt-header-content navy'>
                                                    <span>
                                                        {service?.services?.allowedcoveroptions.find(option => option.servicename === 'Window Glass Cover') ? <MdOutlineDone size={16} /> : <MdOutlineClose size={16} />}
                                                    </span>
                                                </Col>
                                                <Col span={8} className='qt-header-content navy'>
                                                    <span>
                                                        {service?.services?.allowedcoveroptions.find(option => option.servicename === 'Optional Car Hire') ? <MdOutlineDone size={16} /> : <MdOutlineClose size={16} />}
                                                    </span>
                                                </Col>
                                                <Col span={8} className='qt-header-content navy'>
                                                    <span>
                                                        {service?.services?.allowedcoveroptions.find(option => option.servicename === 'Towing & Storage') ? <MdOutlineDone size={16} /> : <MdOutlineClose size={16} />}
                                                    </span>
                                                </Col>
                                            </Row>
                                        ))}

                                    </Col>
                                    <Col span={4} className='qt-header-top'>
                                        {product?.coreServices.map((service,index) => (
                                            <Row className='qt-row covertype' key={index}>
                                                <Col span={12} className='qt-header-content navy'>
                                                    <span>
                                                        {service?.services?.allowedcoveroptions.find(option => option.servicename === 'Roadside Assistance') ? <MdOutlineDone size={16} /> : <MdOutlineClose size={16} />}
                                                    </span>
                                                </Col>
                                                <Col span={12} className='qt-header-content navy'>
                                                    <span>
                                                        {service?.services?.allowedcoveroptions.find(option => option.servicename === 'Panel Beating') ? <MdOutlineDone size={16} /> : <MdOutlineClose size={16} />}
                                                    </span>
                                                </Col>
                                            </Row>
                                        ))}

                                    </Col>
                                    <Col span={2} className='qt-header-top'>
                                        {product?.coreServices.map((service,index) => (
                                            <Row className='qt-row covertype' key={index}>
                                                <Col span={24} className='qt-header-content gray'>
                                                    <span className='getquote' onClick={() => setModalOpen(true)}>Get Quote</span>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                </Card>
            </Col>
            {/** Quotation Modal */}
            {modalOpen && (
                <VehicleQuotationModal
                    openPopup={modalOpen}
                    closePopup={() => setModalOpen(false)}
                    showQuoteSummary={showQuoteSummary}
                />
            )}
            {quoteModalOpen && (
                <VehicleQuoteSummary
                    openPopup={quoteModalOpen}
                    closePopup={() => setQuoteModalOpen(false)}
                    car={car}
                />
            )}
        </Row>
    )
}

export default RequestVehicleQuotation