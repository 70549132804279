import { useState } from "react"
import { Col, Row,Card,Space,Collapse,Checkbox,Rate,Input } from "antd"
import { MdOutlineDashboardCustomize } from "react-icons/md"
import { OrderedListOutlined } from "@ant-design/icons"
import ServicesListView from "./ServicesListView"
import ServicesGridView from "./ServicesGridView"

const displayFilters = (filters)=>(
    filters.map((filter,index)=>(
        <div style={{marginBottom:"6px"}} key={index}>
            <Checkbox>{filter}</Checkbox>
        </div>
    ))
)

const locations = ["Gauteng","Limpopo","North West","Eastern Cape","Western Cape","Free State"]
const agentExperience = ["1 Year", "2 Years", "3 Years", "4 Years", "5 Years", "Over 5 Years"]
const quality = ["New", "Used"]
const providerType = ["Individual", "Company"]
const carBrands = ["Toyota", "Madza", "Nissan", "BMW", "Mercedes Benz", "Audi"]


const CarParts = ()=>{
    const serviceProviders = [
        {
            name: "Naskar Spares",
            image: "https://media.istockphoto.com/id/174987822/photo/towing-away-car.webp?b=1&s=170667a&w=0&k=20&c=cp46fOABPkPaquL_e8mPBIvbuipA4VDpZtZKgM4V_XU=",
            province: "Gauteng",
            suburb: "Centurion",
            type: "Individual",
            availability: "24 hour",
            experience: "3 Years",
            description: ""
        },
        {
            name: "Auto Zone",
            image: "https://media.istockphoto.com/id/1337349217/photo/insurance-officer-using-electronic-pen-to-recording-data-of-broken-pickup-car-on-tablet-while.webp?b=1&s=170667a&w=0&k=20&c=-Dar3erk8n1SqTB6PYE0PIym1e2vUmkI_y-U2N66Oew=",
            province: "Gauteng",
            suburb: "Sandton",
            type: "Individual",
            availability: "24 hour",
            experience: "1 Year",
            description: ""
        },
        {
            name: "Ace Auto Spares",
            image: "https://media.istockphoto.com/id/1311624863/photo/insurance-agent-standing-near-wrecked-car-on-tow-truck.webp?b=1&s=170667a&w=0&k=20&c=CQsqFoT8DeRjG3iEI-15fEfBxpwO8I0WGTvLpw4b2Xw=",
            province: "Gauteng",
            suburb: "Centurion",
            type: "Individual",
            availability: "24 hour",
            experience: "Over 5 Years",
            description: ""
        },
    ]
    const [agentRating,setAgentRating] = useState(4)
    const [activeView,setActiveView] = useState("List")

    return(
        <Row>
            <Col span={24}>
                <Card style={{width:"97%"}}>
                    <Row gutter={[16,10]}>
                        <Col span={24} style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                            <Space size={12}>
                                <OrderedListOutlined 
                                    style={{
                                        fontSize:"19px",
                                        color: activeView === "List" && "#1195ed",
                                        cursor: "pointer"
                                    }} 
                                    onClick={()=> setActiveView("List")}
                                />
                                <MdOutlineDashboardCustomize 
                                    style={{
                                        fontSize:"19px",
                                        color: activeView === "Grid" && "#1195ed",
                                        cursor: "pointer"
                                    }}                             
                                    onClick={()=> setActiveView("Grid")}
                                />
                            </Space>
                        </Col>
                        <Col span={8}>
                            <Card>
                                <div style={{fontSize:"17px",fontWeight:"bold"}}>
                                    Search Car Parts
                                </div>
                                <Input 
                                    placeholder="Search By Name" 
                                    size="large"
                                    style={{marginTop:"16px",marginBottom:"16px"}}
                                />
                                <Collapse
                                    size="small" 
                                    items={[
                                        {
                                            key: 1,
                                            label: "Provider Type",
                                            children: displayFilters(providerType)
                                        }
                                    ]}
                                    style={{marginBottom:"16px"}}
                                />
                                <Collapse
                                    size="small" 
                                    items={[
                                        {
                                            key: 1,
                                            label: "Location",
                                            children: displayFilters(locations)
                                        }
                                    ]}
                                    style={{marginBottom:"16px"}}
                                />
                                <Collapse
                                    size="small" 
                                    items={[
                                        {
                                            key: 1,
                                            label: "Brand",
                                            children: displayFilters(carBrands)
                                        }
                                    ]}
                                    style={{marginBottom:"16px"}}
                                />
                                <Collapse
                                    size="small" 
                                    items={[
                                        {
                                            key: 1,
                                            label: "Quality",
                                            children: displayFilters(quality)
                                        }
                                    ]}
                                    style={{marginBottom:"16px"}}
                                />
                                <div style={{marginBottom:"10px"}}>
                                    Agent Rating
                                </div>
                                <Rate 
                                    size="small" 
                                    value={agentRating}
                                    onChange={(value)=> setAgentRating(value)}
                                />
                            </Card>
                        </Col>
                        <Col span={16}>
                            {activeView === "List" ? <ServicesListView serviceProviders={serviceProviders} /> : <ServicesGridView serviceProviders={serviceProviders} /> }
                        </Col>
                    </Row>
                    <Row gutter={[24,24]}>

                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default CarParts